import styled from "styled-components";

const TicketSlider = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75%;
	margin: 3rem auto;
	gap: 30px;
	flex-wrap: wrap;
`;

const Ticket = styled.div`
	flex-shrink: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 550px;
	width: 340px;
	justify-self: center;
	position: relative;
	overflow: hidden;
	&:after {
		content: "";
		position: absolute;
		display: block;
		width: calc(100% - 60px);
		transform: translateX(30px);
		left: 0;
		top: 66.25%;
		border-top: 3px dashed rgb(197, 197, 197);
	}
`;

const TicketHalf = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	background: white;
	padding: 20px;
	color: var(--text-400);
	width: calc(100% - 40px);
	overflow: hidden;
	position: relative;
	&:first-child {
		border-bottom: 0;
	}
	&:nth-child(2) {
		border-top: 0;
	}
`;

const TicketNumber = styled.div`
	color: var(--text-600);
	font-weight: 500;
`;

const Text = styled.div`
	color: var(--text-400);
	font-weight: 700;
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 4rem 0;
	color: var(--text-100);

	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Button = styled.a`
	display: block;
	width: 100%;
	background: var(--palette-200);
	border-top: 2px solid var(--text-600);
	padding: 0.5rem 0;
	user-select: none;
	color: var(--text-400);
	font-weight: 900;
	letter-spacing: 1px;
	font-size: 14px;
	margin-top: 0.5rem;
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Tickets = () => {
	const ticketData = [
		{
			ticketNumber: "1",
			ticketName: "Conference Only Ticket",
			includes: ["5-star Lunch", "City Tour *"],
			date: "12-14 Aug '23",
			price: "75 USD",
			link: "https://buy.copperx.io/payment/payment-link/10031ae3-12f8-422e-9a37-e41b7cc38ada",
			link2: "https://rzp.io/l/conf23regular",
			people: "1 Person",
		},
		{
			ticketNumber: "2",
			ticketName: "Conference & Party Ticket",
			includes: ["5-star Lunch", "Parties", "City Tour *"],
			date: "11-14 Aug '23",
			price: "125 USD",
			link: "https://buy.copperx.io/payment/payment-link/411ac85b-cce8-4ebd-b619-e8af3d1c5546",
			link2: "https://rzp.io/l/conf23Partyplus",
			people: "1 Person",
		},
		{
			ticketNumber: "3",
			ticketName: "VC Networking Pass",
			includes: [
				"5-star Lunch",
				"Parties",
				"City Tour *",
				"Networking Session",
			],
			date: "11-14 Aug '23",
			price: "600 USD",
			link: "https://buy.copperx.io/payment/payment-link/9e5a8c28-0cbd-4c3d-94a3-b8b96b14feef",
			link2: "https://rzp.io/l/conf23vcpass",
			people: "1 Person",
		},
		{
			ticketNumber: "4",
			ticketName: "VC Networking Full Pass",
			includes: [
				"5-star Lunch",
				"Parties",
				"City Tour *",
				"Networking Session",
				"Accommodation + Meals Full Board",
			],
			date: "11-14 Aug '23",
			price: "1600 USD",
			link: "https://buy.copperx.io/payment/payment-link/e4e5870d-f22c-4fee-b51d-e24a9967c38a",
			link2: "https://rzp.io/l/conf23vcpassplus",
			people: "1 Person",
		},
		{
			ticketNumber: "5",
			ticketName: "Conference Only Ticket",
			includes: ["5-star Lunch", "City Tour *"],
			date: "12-14 Aug '23",
			price: "350 USD",
			link: "https://buy.copperx.io/payment/payment-link/53b5493b-f9eb-48ff-844f-cd5c9bc9e8cd",
			link2: "https://rzp.io/l/conf23groupof5",
			people: "5 People",
		},
		{
			ticketNumber: "6",
			ticketName: "Conference & Party Ticket",
			includes: ["5-star Lunch", "Parties", "City Tour *"],
			date: "11-14 Aug '23",
			price: "600 USD",
			link: "https://buy.copperx.io/payment/payment-link/4c89a985-30aa-4110-acd8-686c6ac68239",
			link2: "https://rzp.io/l/conf23Partyof5",
			people: "5 People",
		},
	];

	return (
		<>
			<Heading id="tickets" className="font-primary">
				Tickets
			</Heading>
			<TicketSlider>
				{ticketData.map(ticket => {
					return (
						<Ticket>
							<TicketHalf style={{ height: "70%" }}>
								<TicketNumber className="inner-element">
									Ticket #{ticket.ticketNumber}
								</TicketNumber>
								<Text
									className="font-secondary"
									style={{
										fontSize: "28px",
										marginTop: "15px",
									}}
								>
									Web3Conf India
								</Text>
								<Text
									className="font-secondary"
									style={{ fontSize: "18px" }}
								>
									Goa Edition, '23
								</Text>

								<Text
									style={{
										color: "var(--text-600)",
										marginTop: "15px",
										fontSize: "14px",
									}}
								>
									Name:
								</Text>
								<Text
									className="font-secondary"
									style={{ fontSize: "14px" }}
								>
									{ticket.ticketName}
								</Text>

								<Text
									style={{
										color: "var(--text-600)",
										marginTop: "15px",
										fontSize: "14px",
									}}
								>
									Covers:
								</Text>
								<div className="flex flex-wrap items-center gap-2">
									{ticket.includes.map(include => (
										<div className="px-2 py-1 font-sans text-xs font-bold rounded-md select-none text-violet-800 bg-violet-200">
											{include}
										</div>
									))}
								</div>

								<Text
									style={{
										color: "var(--text-600)",
										marginBottom: "15px",
										fontSize: "10px",
										position: "absolute",
										bottom: 0,
									}}
								>
									* Limited Seats Are Available And Will Be
									Optional Or Allocated In First Come First
									Serve Basis!
								</Text>
							</TicketHalf>

							<TicketHalf
								style={{
									height: "35%",
									flexDirection: "row",
								}}
							>
								<div style={{ width: "50%" }}>
									<Text
										style={{
											color: "var(--text-600)",
											marginTop: "7px",
											fontSize: "14px",
										}}
									>
										Dates:
									</Text>
									<Text
										className="font-secondary"
										style={{ fontSize: "14px" }}
									>
										{ticket.date}
									</Text>

									<Text
										style={{
											color: "var(--text-600)",
											marginTop: "7px",
											fontSize: "14px",
										}}
									>
										Price:
									</Text>
									<Text
										className="font-secondary"
										style={{
											fontSize: "14px",
										}}
									>
										{ticket.price
											.split(" ")
											.map((word, index) => (
												<span
													key={index}
													className={
														index === 0
															? `text-lg`
															: `text-xs`
													}
												>
													{word}
													{index === 0 && " "}
												</span>
											))}
									</Text>
									<Button
										style={{
											left: 0,
											textDecoration: "none",
											borderRight:
												"2px solid hsla(244, 62%, 10%, 0.5)",
											cursor: "not-allowed",
											pointerEvents: "none",
											background: "#fffbab",
											color: "#636359",
										}}
									>
										SOLD OUT!
									</Button>
								</div>

								<div>
									<Text
										style={{
											color: "var(--text-600)",
											marginTop: "7px",
											fontSize: "14px",
										}}
									>
										People Count:
									</Text>
									<Text
										className="font-secondary"
										style={{ fontSize: "14px" }}
									>
										{ticket.people}
									</Text>
									<Text
										style={{
											color: "var(--text-600)",
											marginTop: "7px",
											fontSize: "14px",
										}}
									>
										Mode Of Payment:
									</Text>
									<Text
										className="font-secondary"
										style={{ fontSize: "14px" }}
									>
										Crypto/Fiat
									</Text>
								</div>
							</TicketHalf>
						</Ticket>
					);
				})}
			</TicketSlider>
		</>
	);
};

export default Tickets;
