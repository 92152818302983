import React, { useState } from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import rhic from "../assets/speakers/rhic.webp";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 4rem 0 3rem 0;
	flex-wrap: wrap;
	row-gap: 2rem;
`;

const Img = styled.img`
	height: 340px;
	width: 340px;
	object-fit: cover;
	background: white;
`;

const Backdrop = styled.div`
	position: absolute;
	top: 100px;
	height: calc(340px - 5px);
	width: calc(340px - 10px);
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.9)
	);
	border-radius: 10px;
	z-index: 0;
`;

const Card = styled.div`
	width: 340px;
	height: 340px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: white;
	border: 5px solid #7748c6;
	border-radius: 15px;

	position: relative;

	margin: 0 1rem;
	overflow: hidden;
	transition: transform 0.2s;
	&:hover {
		transform: scale(1.05) rotate3d(1, 1, 1, -5deg);
		z-index: 2;
	}
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 1rem 0;
	color: var(--text-100);
	@media (max-width: 768px) {
		margin: 3rem 0;
	}
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Name = styled.div`
	font-size: 30px;
	font-weight: 400;
	width: calc(340px - 3rem);
	line-height: 1.2;
	text-align: center;
	margin: 1.25rem 1.125rem;
	text-align: left;
	position: absolute;
	bottom: 16px;
	left: 0;
	color: white;
	letter-spacing: 1px;
	text-transform: uppercase;
	max-width: 215px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-family: "Madeia", "Outfit", "Avenir", "sans-serif";
	user-select: none;
`;

const Bio = styled.div`
	font-size: 18px;
	font-weight: 400;
	width: calc(340px - 3rem);
	text-align: center;
	margin: 1rem 1.125rem;
	text-align: left;
	position: absolute;
	bottom: 0;
	left: 0;
	color: #ccc;
	font-family: "Outfit", "Avenir", "sans-serif";
	user-select: none;
`;

const Twitter = styled.a`
	cursor: pointer;
	position: absolute;
	bottom: 26px;
	right: 0;
	z-index: 2;
	margin-right: 1.125rem;
`;

const Panel = () => {
	const panelInfo = [
		{
			name: "Vivek Madani",
			bio: "Head @ Flipkart-Polygon COE",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/frdurkaen1eickwudh6p",
			twitter: "https://twitter.com/VivekMadani",
		},
		{
			name: "Lomesh Dutta",
			bio: "VP of Growth @ DFINITY",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/rmxewidebglawhj6ahep",
			twitter: "https://twitter.com/lomeshdutta",
		},
		{
			name: "Bandhul Bansal",
			bio: "Co-Founder @ Crewsphere",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/xjpf6xo13p030x5emcml",
			twitter: "https://twitter.com/BandhulBansal",
		},
		{
			name: "Siddharth Banerjee",
			bio: "Founder @ Kandola Network",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/ujf4mzroqddoxatjysjq",
			twitter: "https://twitter.com/sid_kandola",
		},
		{
			name: "Atharva Sabnis",
			bio: "Co-Founder @ Ultimate Digits",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/hvy4z7cmti457pftu0ra",
			twitter: "https://twitter.com/therealatharva",
		},
		{
			name: "Rushabh Parmar",
			bio: "Sr. Blockchain Engineer @ XDC",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/mtwo1apleikucecskwwn",
			twitter: "https://twitter.com/rushabh96975767",
		},
		{
			name: "Prajnyasis Biswal",
			bio: "CMO @ BharatBox",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/v1691788446/2023-08-12_02.43.17_anqjif.jpg",
			twitter: "https://twitter.com/iampbiswal",
		},
	];

	const moderatorInfo = [
		{
			name: "Naandika Tripathi",
			bio: "Correspondent @ Forbes India",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/f5d4envvxfwlsogjb6ra",
			twitter: "https://twitter.com/naandikat",
		},
	];

	return (
		// <div className="flex flex-col md:flex-row justify-center">
		<>
			<div>
				<Heading className="font-primary">Panel Speakers</Heading>
				<Wrapper className="px-0">
					{panelInfo.map((speaker, index) => {
						return (
							<Card key={index} className="shadow-xl">
								<Img src={speaker.img} />
								{speaker.twitter.includes("twitter") ? (
									<Twitter
										href={speaker.twitter}
										target="_blank"
									>
										<svg
											className="w-8 h-8 transition-colors fill-white hover:fill-sky-400"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
										</svg>
									</Twitter>
								) : (
									<Twitter
										href={speaker.twitter}
										target="_blank"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="w-8 h-8 transition-colors fill-white hover:fill-blue-400"
											viewBox="0 0 24 24"
										>
											<path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"></path>
										</svg>
									</Twitter>
								)}
								<Backdrop />
								<Name>{speaker.name}</Name>
								<Bio>{speaker.bio}</Bio>
							</Card>
						);
					})}
				</Wrapper>
			</div>
			{/* <div>
				<Heading className="font-primary">Panel Moderator</Heading>
				<Wrapper className="px-0">
					{moderatorInfo.map((mod, index) => {
						return (
							<Card key={index} className="shadow-xl">
								<Img src={mod.img} />
								{mod.twitter.includes("twitter") ? (
									<Twitter href={mod.twitter} target="_blank">
										<svg
											className="w-8 h-8 transition-colors fill-white hover:fill-sky-400"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
										</svg>
									</Twitter>
								) : (
									<Twitter href={mod.twitter} target="_blank">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="w-8 h-8 transition-colors fill-white hover:fill-blue-400"
											viewBox="0 0 24 24"
										>
											<path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"></path>
										</svg>
									</Twitter>
								)}
								<Backdrop />
								<Name>{mod.name}</Name>
								<Bio>{mod.bio}</Bio>
							</Card>
						);
					})}
				</Wrapper>
			</div> */}
		</>
		// </div>
	);
};

export default Panel;
