import React, { useState } from "react";
import styled from "styled-components";
import ContactBG from "../assets/contact.png";
import ContactBGNight from "../assets/contact-night.png";
import Marquee from "react-fast-marquee";

const Wrapper = styled.div`
	--background: hsl(264, 62%, 44%);
	--background-transparent: hsla(264, 69%, 15%, 0.75);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
`;

const Box = styled.div`
	color: black;
	font-size: 20px;
	font-weight: 800;
	width: 100%;
	margin: 3rem 0;
	margin-bottom: 1rem;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	position: relative;
	border-top: 4px solid var(--text-600);
	border-bottom: 4px solid var(--text-600);
	@media (max-width: 768px) {
		margin-top: 1rem;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		border-top: 2px solid var(--border-500);
		border-bottom: 2px solid var(--border-500);
	}
`;

const Bottom = styled.div`
	color: var(--border-300);
	font-size: 16px;
	font-weight: 600;
	background: transparent;
	position: relative;
	height: 100%;
	img {
		opacity: 0.75;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

const Top = styled.div`
	color: var(--border-300);
	font-size: 16px;
	font-weight: 600;
	background: transparent;
	position: relative;
	height: 100%;
	img {
		opacity: 0.75;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

const Middle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--border-300);
	font-size: 16px;
	font-weight: 600;
	background: transparent;
	padding: 16px 18px;
	background: var(--background);
	height: 100%;
	@media (max-width: 768px) {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background: var(--background-transparent);
		padding-bottom: 1.75rem;
		backdrop-filter: blur(5px) saturate(1.5) brightness(0.66);
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			background: var(--background-transparent);
			backdrop-filter: blur(5px) saturate(1.5) brightness(0.66);
		}
		div {
			position: static;
			z-index: 2;
		}
	}
`;

const Backdrop = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to ${props => props.dir},
		rgba(0, 0, 0, 0.33),
		var(--background)
	);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	@media (max-width: 768px) {
		display: none;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0.8rem 0;
	z-index: 2;
	&:not(:first-child) {
		margin-top: 0;
	}
`;

const Text = styled.div`
	width: 100%;
	text-align: left;
	color: var(--text-100);
	font-weight: 700;
	font-family: "Recoleta", "Outfit", sans-serif;
	font-size: 20px;
`;

const Input = styled.input`
	width: 100%;
	text-align: left;
	color: var(--text-100);
	font-weight: 500;
	outline: none;
	border: 2px solid var(--border-300);
	border-radius: 5px;
	padding: 12px;
	margin-top: 6px;
	transition: border-color 0.15s;
	&:focus-visible {
		border-color: var(--text-100);
	}
	&::placeholder {
		color: var(--text-500);
	}
`;

const TextArea = styled.textarea`
	width: 100%;
	text-align: left;
	color: var(--text-100);
	font-weight: 500;
	outline: none;
	border: 2px solid var(--border-300);
	border-radius: 5px;
	padding: 12px;
	margin-top: 6px;
	resize: none;
	&:focus-visible {
		border-color: var(--text-100);
	}
	&::placeholder {
		color: var(--text-500);
	}
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 0 0 2.5rem 0;
	color: var(--text-100);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(/overlay.png);
	opacity: 0.6;
	z-index: 2;
	@media (max-width: 768px) {
		display: none;
	}
`;

const Contact = () => {
	const getMailToLink = (
		name: string,
		company: string,
		message: string
	): string => {
		let base = "mailto:";
		let to = "support@web3confindia.xyz";
		base += to;
		base += `?subject=${encodeURIComponent(`Web3Conf India 2023`)}`;
		base += `&body=${encodeURIComponent(
			`Hey I'm ${name} from ${company}!`
		)}%0A%0A${message}`;
		return base;
	};

	const [errorComponent, setErrorComponent] = useState({
		name: false,
		company: false,
		message: false,
	});

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setErrorComponent({
			name: name === "",
			company: company === "",
			message: message === "",
		});
		if (name !== "" && company !== "" && message !== "") {
			window.location.href = getMailToLink(name, company, message);
		}
	};

	const [name, setName] = useState("");
	const [company, setCompany] = useState("");
	const [message, setMessage] = useState("");
	return (
		<Wrapper>
			<Heading className="font-primary">Sponsor Us</Heading>
			<Box className="shadow-xl">
				<Overlay />
				<Top>
					<img
						src={ContactBG}
						alt="Person looking at beach with sunset"
					/>
					<Backdrop dir="right" />
				</Top>
				<Middle>
					<Row>
						<Text>Name</Text>
						<Input
							value={name}
							onChange={e => setName(e.target.value)}
							placeholder="John Doe"
						/>
					</Row>
					<Row>
						<Text>Company</Text>
						<Input
							value={company}
							onChange={e => setCompany(e.target.value)}
							placeholder="Example Inc"
						/>
					</Row>
					<Row>
						<Text>Message</Text>
						<TextArea
							value={message}
							onChange={e => setMessage(e.target.value)}
							placeholder="Hey, would love to get on a call regarding sponsorships!"
						></TextArea>
					</Row>
					<Row className="!mb-0 md:!mb-[0.8rem]">
						<a className="mt-4 md:mt-0 overflow-hidden min-w-[8rem] group font-primary text-3xl px-3 py-1 pr-4 pt-2 border-4 border-[var(--text-400)] font-medium transition-colors hover:shadow-lg hover:bg-[var(--hover-100)] bg-[var(--palette-200)] text-[var(--text-400)] rounded-lg cursor-pointer">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="inline w-6 h-6 mr-2 transition-transform -translate-x-12 group-hover:translate-x-0"
							>
								<path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
								<path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
							</svg>
							<button
								onClick={handleClick}
								className="inline-block transition-transform -translate-x-3.5 group-hover:translate-x-0"
							>
								SEND MAIL
							</button>
						</a>
					</Row>
					<img
						src={ContactBGNight}
						className="absolute top-0 left-0 md:hidden object-cover w-full h-full block z-[-1]"
						alt="Beach party with people at night"
					/>
				</Middle>
				<Bottom>
					<img
						src={ContactBGNight}
						alt="Beach party with people at night"
					/>
					<Backdrop dir="left" />
				</Bottom>
			</Box>

			<Marquee
				style={{
					transform: "translateY(50%)",
				}}
				speed={75}
				className="marquee-wrapper"
				gradient={false}
			>
				{Array(5)
					.fill(
						`CALL FOR SPONSORSHIPS ARE NOW OPEN,  SEND US A MAIL AT SPONSOR@WEB3CONFINDIA.XYZ BEFORE 30TH JULY '23 TO SECURE YOUR SPOT! \t\t`
					)
					.join("")}
			</Marquee>
		</Wrapper>
	);
};

export default Contact;
