import "./styles/styles.scss";
import Navbar from "components/Navbar";
import Hero from "components/Hero";
import Tickets from "components/Tickets";
import Agenda from "components/Agenda";
import Memories from "components/Memories";
import Contact from "components/Contact";
import Footer from "components/Footer";
import PastSponsors from "components/PastSponsors";
import PromoVideo from "components/PromoVideo";
import Applications from "components/Applications";
import Team from "components/Team";
import Volunteers from "components/Volunteers";
import Speaker from "components/Speaker";
import Socials from "components/Socials";
import Opportunity from "components/Opportunity";
import About from "components/About";
import Venue from "components/Venue";
import Sponsors from "components/Sponsors";
import Keynote from "components/Keynote";
import AppDownload from "components/AppDownload";
import Panel from "components/Panel";
import Hackathon from "components/Hackathon";

function App() {
	return (
		<>
			<Navbar />
			{/* <PromoVideo /> */}
			<Hero />
			<About />
			<Agenda />
			<Sponsors />
			<Keynote />
			<Speaker />
			<Panel />
			<Hackathon />
			<Venue />
			<Tickets />
			<Applications />
			<PastSponsors />
			<Opportunity />
			<Memories />
			<AppDownload />
			<Contact />
			<Footer />
		</>
	);
}

export default App;
