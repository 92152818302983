import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import { Analytics } from "@vercel/analytics/react";

import "./index.css";
import "tailwindcss/tailwind.css";

import App from "./App";
import { ParallaxProvider } from "react-scroll-parallax";

const container = document.getElementById("root");
if (container) {
	const root = createRoot(container);
	root.render(
		<StrictMode>
			<ParallaxProvider>
				<App />
			</ParallaxProvider>
			<Analytics />
		</StrictMode>
	);
}
