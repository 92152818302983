import { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import { useWindowSize } from "hooks/useWindowSize";
import { useWindowScroll } from "hooks/useWindowScroll";

const Navbar: React.FC = () => {
	const { y } = useWindowScroll();
	const { height } = useWindowSize();

	const [shift, setShift] = useState<boolean>(false);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (y > height! * 0.5) {
			setShift(true);
		} else {
			setShift(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [y]);

	return (
		<nav className="fixed top-0 left-0 z-50 w-full">
			<div
				className={`flex items-center justify-between transition-all w-full mx-auto border-b-2 ${
					shift
						? `px-8 py-4 shadow-lg backdrop-blur-xl bg-violet-100 bg-opacity-80 border-[var(--text-600)] border-opacity-100`
						: `p-6 border-transparent shadow-none`
				}`}
			>
				<a
					href="/"
					className="flex items-center w-12 h-12 space-x-4 overflow-hidden rounded-full shadow-md cursor-pointer md:w-16 md:h-16"
				>
					<img
						src={Logo}
						alt="Web3Conf Logo"
						className="w-full h-full"
					/>
				</a>

				<div className="flex flex-row justify-end items-center gap-3">
					<div className="cursor-hover-effect flex h-full">
						<a
							href="#AppStore"
							className="cursor-hover-effect overflow-hidden md:min-w-[8rem] group font-primary text-2xl md:text-3xl px-3 py-1 md:pr-4 pt-2 border-4 border-[var(--text-400)] font-medium transition-colors hover:shadow-lg hover:bg-[var(--hover-100)] bg-[var(--palette-200)] text-[var(--text-400)] rounded-lg cursor-pointer"
						>
							<svg
								version="1.1"
								id="Layer_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlnsXlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 63 70"
								xmlSpace="preserve"
								className="inline w-6 h-6 sm:mr-1 transition-transform sm:-translate-x-12 md:mr-2 group-hover:translate-x-0"
							>
								<g id="Layer_2_1_">
									<g id="Layer_1-2_1_">
										<path
											d="M35.5833 50.5003H6.41666V15.5003H35.5833V18.417H41.4167V6.75033C41.4167 3.54199 38.7917 0.916992 35.5833 0.916992H6.41666C3.20833 0.916992 0.583328 3.54199 0.583328 6.75033V59.2503C0.583328 62.4587 3.20833 65.0837 6.41666 65.0837H35.5833C38.7917 65.0837 41.4167 62.4587 41.4167 59.2503V47.5837H35.5833V50.5003ZM45.4417 26.0587L46.6083 28.5962C46.8708 29.1795 47.6875 29.1795 47.95 28.5962L49.1167 26.0587L51.6542 24.892C52.2375 24.6295 52.2375 23.8128 51.6542 23.5503L49.1167 22.3837L47.95 19.8462C47.6875 19.2628 46.8708 19.2628 46.6083 19.8462L45.4417 22.3837L42.9042 23.5503C42.3208 23.8128 42.3208 24.6295 42.9042 24.892L45.4417 26.0587Z"
											fill="black"
										/>
										<path
											d="M31.325 24.2504L29.0208 29.3546L23.9167 31.6588C23.6621 31.7748 23.4462 31.9616 23.2948 32.1969C23.1435 32.4322 23.063 32.7061 23.063 32.9858C23.063 33.2656 23.1435 33.5395 23.2948 33.7748C23.4462 34.0101 23.6621 34.1969 23.9167 34.3129L29.0208 36.6171L31.325 41.7504C31.441 42.005 31.6278 42.2209 31.8631 42.3723C32.0984 42.5236 32.3723 42.6041 32.6521 42.6041C32.9319 42.6041 33.2058 42.5236 33.4411 42.3723C33.6764 42.2209 33.8632 42.005 33.9792 41.7504L36.2833 36.6463L41.4167 34.3421C41.6713 34.2261 41.8871 34.0393 42.0385 33.804C42.1899 33.5687 42.2704 33.2948 42.2704 33.015C42.2704 32.7352 42.1899 32.4613 42.0385 32.226C41.8871 31.9907 41.6713 31.8039 41.4167 31.6879L36.3125 29.3838L34.0083 24.2504C33.8889 23.9955 33.6992 23.7798 33.4616 23.6288C33.224 23.4777 32.9482 23.3975 32.6667 23.3975C32.3851 23.3975 32.1094 23.4777 31.8717 23.6288C31.6341 23.7798 31.4445 23.9955 31.325 24.2504ZM46.5792 37.3754L45.4125 39.9129L42.875 41.0796C42.2917 41.3421 42.2917 42.1588 42.875 42.4213L45.4125 43.5879L46.5792 46.1254C46.8417 46.7088 47.6583 46.7088 47.9208 46.1254L49.0875 43.5879L51.625 42.4213C52.2083 42.1588 52.2083 41.3421 51.625 41.0796L49.0875 39.9129L47.9208 37.3754C47.8587 37.2507 47.7629 37.1458 47.6444 37.0725C47.526 36.9991 47.3894 36.9603 47.25 36.9603C47.1107 36.9603 46.9741 36.9991 46.8556 37.0725C46.7371 37.1458 46.6413 37.2507 46.5792 37.3754Z"
											fill="black"
										/>
									</g>
								</g>
							</svg>
							<div className="transition-transform -translate-x-3.5 group-hover:translate-x-0 hidden sm:inline-block">
								GET EVENT APP!
							</div>
						</a>
					</div>
					<div className="cursor-hover-effect flex h-full">
						<a
							href="https://twitter.com/web3conf_india"
							className="cursor-hover-effect overflow-hidden md:min-w-[8rem] group font-primary text-2xl md:text-3xl px-3 py-1 md:pr-4 pt-2 border-4 border-[var(--text-400)] font-medium transition-colors hover:shadow-lg hover:bg-[var(--hover-100)] bg-[var(--palette-200)] text-[var(--text-400)] rounded-lg cursor-pointer"
						>
							<svg
								version="1.1"
								id="Layer_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlnsXlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 60.3 66.2"
								xmlSpace="preserve"
								className="inline w-6 h-6 sm:mr-1 transition-transform sm:-translate-x-12 md:mr-2 group-hover:translate-x-0"
							>
								<g id="Layer_2_1_">
									<g id="Layer_1-2_1_">
										<path
											d="M60.6061 21.861C60.6341 22.428 60.6446 23.002 60.6446 23.569C60.6446 41.034 47.3551 61.166 23.0545 61.166C15.589 61.166 8.64855 58.9785 2.80005 55.2265C6.25936 55.6448 9.76721 55.3773 13.1231 54.4393C16.479 53.5012 19.6171 51.911 22.358 49.7595C19.6015 49.7074 16.9302 48.7952 14.7175 47.1504C12.5048 45.5057 10.8614 43.2106 10.017 40.586C11.9983 40.9565 14.0373 40.8788 15.9845 40.3585C12.9942 39.7521 10.3054 38.1305 8.37396 35.7685C6.44248 33.4064 5.38705 30.4492 5.38655 27.398V27.2335C7.22124 28.2541 9.27318 28.8217 11.3715 28.889C8.5711 27.022 6.58872 24.1567 5.82901 20.8779C5.0693 17.599 5.58954 14.1538 7.28355 11.2455C10.6008 15.3281 14.7398 18.6673 19.4316 21.046C24.1235 23.4248 29.2632 24.79 34.5171 25.053C34.2898 24.0647 34.1759 23.0536 34.1776 22.0395C34.1776 20.305 34.5192 18.5876 35.1831 16.9852C35.847 15.3828 36.82 13.9269 38.0466 12.7006C39.2732 11.4743 40.7294 10.5016 42.332 9.83821C43.9345 9.17477 45.6521 8.83354 47.3865 8.834C49.1942 8.83105 50.9831 9.19954 52.6424 9.91661C54.3017 10.6337 55.796 11.684 57.0326 13.0025C59.9905 12.4169 62.8274 11.3328 65.4221 9.7965C64.4352 12.8562 62.3705 15.4533 59.6121 17.1045C62.231 16.794 64.7886 16.0933 67.2001 15.0255C65.4286 17.68 63.1952 19.9952 60.6061 21.861Z"
											fill="black"
										/>
									</g>
								</g>
							</svg>
							<div className="ml-1 transition-transform -translate-x-3.5 group-hover:translate-x-0 hidden sm:inline-block">
								FOLLOW US ON TWITTER
							</div>
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
