import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	--background: hsl(264, 62%, 44%);
	--background-transparent: hsla(264, 69%, 15%, 0.75);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 2rem 0 0 0;
	color: var(--text-100);
	@media (max-width: 768px) {
		margin: 2.5rem 0 0 0;
	}
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const TicketSlider = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75%;
	margin: 3rem auto;
	gap: 30px;
	flex-wrap: wrap;
`;

const TicketNumber = styled.div`
	color: var(--text-600);
	font-weight: 500;
	margin-top: 1rem;
`;

const Text = styled.div`
	color: var(--text-400);
	font-weight: 700;
`;

const Button = styled.a`
	display: block;
	width: 100%;
	background: var(--palette-200);
	border-top: 2px solid var(--text-600);
	padding: 0.5rem 0;
	user-select: none;
	color: var(--text-400);
	font-weight: 900;
	letter-spacing: 1px;
	font-size: 14px;
	margin-top: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Card = styled.div`
	width: 500px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	background: white;
	border: 5px solid black;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
`;

const Opportunity = () => {
	return (
		<Wrapper>
			<Heading className="font-primary">Building Opportunities</Heading>

			<TicketSlider>
				<Card>
					<TicketNumber>Opportunity #1</TicketNumber>
					<Text
						className="font-secondary"
						style={{
							fontSize: "28px",
							marginTop: "15px",
						}}
					>
						Hack Web3Conf
					</Text>
					<Text
						className="font-secondary"
						style={{
							fontSize: "14px",
							width: "80%",
							margin: "1rem 0",
						}}
					>
						Hack Web3Conf hackathon will be organized in June-July
						so that the best projects can pitch their ideas during
						the VC networking sessions, sit on panel discussions to
						inspire other builders, and can get funded to start
						their entrepreneurial journey as well as win FREE passes
						to the Web3Conf India.
					</Text>

					<Text
						style={{
							color: "var(--text-600)",
							marginTop: "15px",
							fontSize: "14px",
						}}
					>
						Covers:
					</Text>
					<div className="flex flex-wrap items-center gap-2 pb-[1rem]">
						<div className="px-2 py-1 font-sans text-xs font-bold rounded-md select-none text-violet-800 bg-violet-200">
							Free Passes
						</div>
					</div>

					<Button
						href={"#"}
						style={{
							textDecoration: "none",
							cursor: "not-allowed",
							pointerEvents: "none",
							background: "#fffbab",
							color: "#636359",
						}}
					>
						Applications Closed
					</Button>
				</Card>

				<Card>
					<TicketNumber>Opportunity #2</TicketNumber>
					<Text
						className="font-secondary"
						style={{
							fontSize: "28px",
							marginTop: "15px",
						}}
					>
						Pitch Contest
					</Text>
					<Text
						className="font-secondary"
						style={{
							fontSize: "16px",
							width: "80%",
							margin: "1rem 0",
						}}
					>
						We have something for the entrepreneurs out there! Apply
						with your startup pitch and the best pitches get to
						pitch live at the VC Networking Session alongwith a free
						pass to attend for one of their team members.
					</Text>

					<Text
						style={{
							color: "var(--text-600)",
							marginTop: "15px",
							fontSize: "14px",
						}}
					>
						Covers:
					</Text>
					<div className="flex flex-wrap items-center gap-2 pb-[1rem]">
						<div className="px-2 py-1 font-sans text-xs font-bold rounded-md select-none text-violet-800 bg-violet-200">
							Free Passes
						</div>
					</div>

					<Button
						href={"#"}
						style={{
							textDecoration: "none",
							cursor: "not-allowed",
							pointerEvents: "none",
							background: "#fffbab",
							color: "#636359",
						}}
					>
						Applications Closed
					</Button>
				</Card>
			</TicketSlider>
		</Wrapper>
	);
};

export default Opportunity;
