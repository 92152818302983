import React, { useState } from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import rhic from "../assets/speakers/rhic.webp";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 3rem auto;
	flex-wrap: wrap;
	row-gap: 2rem;
	margin-top: 4.5rem;
`;

const Img = styled.img`
	height: 280px;
	width: 280px;
	object-fit: cover;
	background: white;
`;

const Backdrop = styled.div`
	position: absolute;
	top: 100px;
	height: calc(280px - 5px);
	width: calc(280px - 10px);
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.9)
	);
	border-radius: 10px;
	z-index: 0;
`;

const Card = styled.div`
	width: 280px;
	height: 280px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: white;
	border: 5px solid #7748c6;
	border-radius: 15px;

	position: relative;

	margin: 0 1rem;
	overflow: hidden;
	transition: transform 0.2s;
	&:hover {
		transform: scale(1.05) rotate3d(1, 1, 1, -5deg);
		z-index: 2;
	}
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 0 0 1rem 0;
	color: var(--text-100);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Name = styled.div`
	font-size: 32px;
	font-weight: 400;
	width: calc(280px - 3rem);
	line-height: 1.2;
	text-align: center;
	margin: 1.25rem 1.125rem;
	text-align: left;
	position: absolute;
	bottom: 16px;
	left: 0;
	color: white;
	letter-spacing: 1px;
	text-transform: uppercase;
	max-width: 215px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-family: "Madeia", "Outfit", "Avenir", "sans-serif";
	user-select: none;
`;

const Bio = styled.div`
	font-size: 16px;
	font-weight: 400;
	width: calc(320px - 3rem);
	text-align: center;
	margin: 1rem 1.125rem;
	text-align: left;
	position: absolute;
	bottom: 0;
	left: 0;
	color: #ccc;
	font-family: "Outfit", "Avenir", "sans-serif";
	user-select: none;
`;

const Twitter = styled.a`
	cursor: pointer;
	position: absolute;
	bottom: 26px;
	right: 0;
	z-index: 2;
	margin-right: 1.125rem;
`;

const Keynote = () => {
	const speakersInfo = [
		{
			name: "Ajeet Khurana",
			bio: "Founder @ Reflexical",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/vnq7wzvsx1bm2z7rfyjt",
			twitter: "https://twitter.com/AjeetK?s=20",
		},
		{
			name: "Amit Hardi",
			bio: "Chief Gaming Officer @ Indigg",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/waregiugotkcfkaz9hcq",
			twitter: "https://twitter.com/AmitHardi",
		},
		{
			name: "Dilip Chenoy",
			bio: "Chairman @ Bharat Web 3 Assoc.",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/tntf2idzfwfvpneqpxym",
			twitter: "https://twitter.com/chenoy_dilip",
		},
		{
			name: "Prasanna Lohar",
			bio: "Founder @ India Blockchain Forum",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v9van6xplu6j5dcvojx7",
			twitter: "https://twitter.com/loharprasanna",
		},
		{
			name: "Tanvi Ratna",
			bio: "Founder @ Policy 4.0",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/u68xsnpyym7uhefeoolm",
			twitter: "https://twitter.com/tanvi_ratna?",
		},
		{
			name: "Anisha Patnaik",
			bio: "Head of Legal @ The Chain Project",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/ok4rxspnaqfnr7xybmtk",
			twitter: "https://twitter.com/anishapatnaik",
		},
		{
			name: "Shusen Yang",
			bio: "Business Director, OKX Wallet",
			img: "https://res.cloudinary.com/boidu/image/upload/v1691601069/2023-08-09_22.36.42_r0ypyd.webp",
			twitter: "https://twitter.com/yang_shusen",
		},
		{
			name: "Ajay Singh",
			bio: "General Partner @ D26 Ventures",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/ojrtwwbqmmsgxluee2qo",
			twitter: "https://twitter.com/singhajaysingh",
		},
		{
			name: "Raj Kapoor",
			bio: "Founder @ IBA",
			img: "https://res.cloudinary.com/boidu/image/upload/v1689967786/52_-_Raj_Kapoor_i5uskm.webp",
			twitter: "https://twitter.com/rajkapoor1964",
		},
		{
			name: "Preetam Rao",
			bio: "CEO & Co-founder @ QuillAudits",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/irua8boc22lavy0xej9o",
			twitter: "https://twitter.com/raopreetam_",
		},
		{
			name: "Parth Chaturvedi",
			bio: "Investments Lead  @ CoinSwitch Ventures",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/pcgwpoawtpmzj0wiqv57",
			twitter: "https://twitter.com/Parth4vedi",
		},

		{
			name: "Abhyudoy Das",
			bio: "Country Manager @ Bybit",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/iituaf1d2xmp8hkxgejl",
			twitter: "https://twitter.com/DasAbhyudoy?s=20",
		},
		{
			name: "Francesco Ciulla",
			bio: "Dev Advocate @ daily.dev",
			img: "https://res.cloudinary.com/boidu/image/upload/v1689967786/Francesco_Ciulla_-_Francesco_Ciulla_uziv95.webp",
			twitter: "https://twitter.com/FrancescoCiull4",
		},
		{
			name: "Deep Chenna",
			bio: "Web3 Engineer @ Google Cloud",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/uqdiidfk4tlbtpv8wdm7",
			twitter: "https://twitter.com/deepchenna",
		},

		{
			name: "Vikram R Singh",
			bio: "CEO @ Antier Solutions",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/ag9plu6gicg89ao31u5v",
			twitter:
				"https://twitter.com/vikramrsingh?s=21&t=KUisT5Pm6ztAOGF2_TcYeA",
		},
		{
			name: "Varun Satyam",
			bio: "Founder @ Davos",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/o7abhdz9vlt82fuh4dap",
			twitter:
				"https://twitter.com/satyam284?s=21&t=KUisT5Pm6ztAOGF2_TcYeA",
		},
		{
			name: "Kasif syed",
			bio: "BD manager @ CoinEx",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/mjc0pwssy2tqdswpkf4y",
			twitter: "https://twitter.com/kashyfsyed",
		},
		{
			name: "Satheesh Kumar",
			bio: "Web3 Engineer @ XDC",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/dz81vplukinsucskh7ks",
			twitter: "https://twitter.com/satz_tr",
		},
		{
			name: "Rick Carback",
			bio: "Co-Founder @ xx network",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/e5g1bcb6iqrodj82h634",
			twitter: "https://twitter.com/rcarback",
		},
		{
			name: "Naveen Saraswat",
			bio: "Founder @ Fantazy",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/otxwgv4fdz8t5pnxn63g",
			twitter:
				"https://twitter.com/naveensaraswat_?s=21&t=KUisT5Pm6ztAOGF2_TcYeA",
		},
		{
			name: "Pratik Shigli",
			bio: "Community Contributor @ dYdX",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/ehucbbwcxzdyugvkopki",
			twitter: "https://twitter.com/VivanLive",
		},
		{
			name: "Ravish Jain",
			bio: "Consultant - Blockchain / AI",
			img: "https://res.cloudinary.com/boidu/image/upload/v1689967786/RavishJain_-_Ravish_Jain_ophucn.webp",
			twitter: "https://twitter.com/ravish_eth",
		},
		{
			name: "Abhay Singh",
			bio: "Co-Founder @ DappLooker",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/cnogxpaja6w3w0zu5ocu",
			twitter: "https://twitter.com/abhayait",
		},
		{
			name: "Mirzad makhdoom",
			bio: "Founder & CEO @ Tribe Academy",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/us7imvtwq00ztzbv6raf",
			twitter: "https://twitter.com/cryptotribe_in",
		},
		{
			name: "Divya Mohan",
			bio: "Head of Engg. @ Flipkart Labs",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/wipj3hhp7r0i9nzhbytk",
			twitter: "https://www.linkedin.com/in/divya-mohan-245b2615/",
		},
		{
			name: "Karan Keswani",
			bio: "CEO @ BharatBox",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/nnas1br43u2h4yle3qsp",
			twitter: "https://twitter.com/KaranVerse",
		},
		{
			name: "Ashutosh Dutt",
			bio: "Ops & Investment @ Arweave",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/a2hproddzhvcrxpo2juw",
			twitter: "https://www.linkedin.com/in/ashutosh-dutt-803b2812a/",
		},
		{
			name: "Anupam Varshney",
			bio: "Founder @ Binamite & Octaloop",
			img: "https://res.cloudinary.com/dtqix6um6/image/upload/v1691920526/Anupam_yn5ms6.png",
			twitter: "https://www.linkedin.com/in/anupamvarshney/",
		},
	];

	return (
		<>
			<Heading className="font-primary">Keynote Speakers</Heading>
			<Wrapper className="px-0">
				{speakersInfo.map((speaker, index) => {
					return (
						<Card key={index} className="shadow-xl">
							<Img src={speaker.img} />
							{speaker.twitter.includes("twitter") ? (
								<Twitter href={speaker.twitter} target="_blank">
									<svg
										className="w-8 h-8 transition-colors fill-white hover:fill-sky-400"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
									</svg>
								</Twitter>
							) : (
								<Twitter href={speaker.twitter} target="_blank">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="w-8 h-8 transition-colors fill-white hover:fill-blue-400"
										viewBox="0 0 24 24"
									>
										<path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"></path>
									</svg>
								</Twitter>
							)}
							<Backdrop />
							<Name
								className={
									speaker.name === "Parth Chaturvedi"
										? "!bottom-8"
										: ""
								}
							>
								{speaker.name}
							</Name>
							<Bio
								className={
									speaker.name === "Parth Chaturvedi"
										? "leading-5 !w-[calc(320px-8rem)]"
										: ""
								}
							>
								{speaker.bio}
							</Bio>
						</Card>
					);
				})}
			</Wrapper>
		</>
	);
};

export default Keynote;
