import Marquee from "react-fast-marquee";
import styled from "styled-components";
import adityac from "../assets/speakers/aditya.webp";
import adityav from "../assets/speakers/adityav.webp";
import akanshaj from "../assets/speakers/akanshaj.webp";
import anama from "../assets/speakers/anam.webp";
import arbazh from "../assets/speakers/arbazh.webp";
import aryan from "../assets/speakers/aryan.webp";
import aviral from "../assets/speakers/aviral.webp";
import divyanshu from "../assets/speakers/divyanshu.webp";
import guru from "../assets/speakers/guru.jpg";
import harpalj from "../assets/speakers/harpalsinh.webp";
import harshs from "../assets/speakers/harshs.webp";
import joelj from "../assets/speakers/joel.webp";
import keyurt from "../assets/speakers/keyur.webp";
import khushboov from "../assets/speakers/khushboo.webp";
import krishnau from "../assets/speakers/krishna.webp";
import manavd from "../assets/speakers/manavd.webp";
import mohitb from "../assets/speakers/mohit.webp";
import nitanshul from "../assets/speakers/nitanshu.webp";
import pradhumnas from "../assets/speakers/pradumna.webp";
import pranshur from "../assets/speakers/pranshu.webp";
import rachitm from "../assets/speakers/rachitm.webp";
import rachitma from "../assets/speakers/rachitma.webp";
import rhic from "../assets/speakers/rhic.webp";
import risho from "../assets/speakers/rish.webp";
import sakshamt from "../assets/speakers/sakshamt.webp";
import saliln from "../assets/speakers/salil.webp";
import sandeeps from "../assets/speakers/sandeep.webp";
import sandipank from "../assets/speakers/sandipan.webp";
import sankalpp from "../assets/speakers/sankalp.webp";
import satheeshk from "../assets/speakers/satheesh.webp";
import shatabarto from "../assets/speakers/shatabarto.webp";
import shrutij from "../assets/speakers/shruti.webp";
import shubhamp from "../assets/speakers/shubham.webp";
import simarpreets from "../assets/speakers/simarpreets.webp";
import sohamz from "../assets/speakers/soham.webp";
import sushrut from "../assets/speakers/sushrut.webp";
import swarnabhas from "../assets/speakers/swarnabha.webp";
import ujwals from "../assets/speakers/ujwal.webp";
import vanshikas from "../assets/speakers/vanshika.webp";
import venkateshk from "../assets/speakers/venkatesh.webp";
import virajm from "../assets/speakers/viraj.webp";
import vivekp from "../assets/speakers/vivekp.webp";
import yashj from "../assets/speakers/yash.webp";
import yaxs from "../assets/speakers/yaxs.webp";
import zishans from "../assets/speakers/zishans.webp";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 3rem auto;
	flex-wrap: wrap;
	row-gap: 2rem;
	padding: 0 4rem;
	margin-top: 4.5rem;
`;

const Img = styled.img`
	height: 300px;
	width: 300px;
	object-fit: cover;
	background: white;
`;

const Backdrop = styled.div`
	position: absolute;
	top: 100px;
	height: calc(200px - 5px);
	width: calc(300px - 10px);
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.8)
	);
	border-radius: 10px;
	z-index: 0;
`;

const Card = styled.div`
	width: 250px;
	height: 250px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: white;
	border: 5px solid #7748c6;
	border-radius: 15px;

	position: relative;

	margin: 0 1rem;
	overflow: hidden;
	transition: transform 0.2s;
	&:hover {
		transform: scale(1.05) rotate3d(1, 1, 1, -5deg);
		z-index: 2;
	}
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 1rem 0;
	padding-top: 0.66rem;
	color: var(--text-100);
	@media (max-width: 768px) {
		margin: 3rem 0;
	}
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Name = styled.div`
	font-size: 28px;
	font-weight: 400;
	width: calc(300px - 3rem);
	line-height: 1.2;
	text-align: center;
	margin: 1rem 1rem;
	text-align: left;
	position: absolute;
	bottom: 16px;
	left: 0;
	color: white;
	letter-spacing: 1px;
	text-transform: uppercase;
	max-width: 215px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-family: "Madeia", "Outfit", "Avenir", "sans-serif";
	user-select: none;
`;

const Bio = styled.div`
	font-size: 16px;
	font-weight: 400;
	width: calc(300px - 3rem);
	text-align: center;
	margin: 1rem 1rem;
	text-align: left;
	position: absolute;
	bottom: 0;
	left: 0;
	color: #ccc;
	font-family: "Outfit", "Avenir", "sans-serif";
	user-select: none;
`;

const Twitter = styled.a`
	cursor: pointer;
	position: absolute;
	bottom: 30px;
	right: 0;
	z-index: 2;
	margin-right: 1rem;
`;

const Speaker = () => {
	const speakersInfo = [
		// {
		// 	name: "Saurabh Bangad",
		// 	bio: "Google",
		// 	img: saurabhb,
		// 	twitter: "https://www.linkedin.com/in/saurabhbangad/",
		// },
		{
			name: "Vanshika Srivastava",
			bio: "Biconomy",
			img: vanshikas,
			twitter: "https://twitter.com/ThisisVanshika/",
		},
		// {
		// 	name: "Sahil Sen",
		// 	bio: "QuickNode",
		// 	img: sahils,
		// 	twitter: "https://twitter.com/sensahil",
		// },
		{
			name: "Rhic Chowdhury",
			bio: "Biconomy",
			img: rhic,
			twitter: "https://twitter.com/0xRhic",
		},
		{
			name: "Manav Darji",
			bio: "Polygon Labs",
			img: manavd,
			twitter: "https://twitter.com/manav24_",
		},
		{
			name: "Saksham Taneja",
			bio: "Polygon Labs",
			img: sakshamt,
			twitter: "https://twitter.com/sakshamtaneja00",
		},
		{
			name: "Krishna Upadhyaya",
			bio: "Polygon Labs",
			img: krishnau,
			twitter: "https://twitter.com/0xkris16",
		},
		{
			name: "Soham Zemse",
			bio: "Ethereum Foundation",
			img: sohamz,
			twitter: "https://twitter.com/zemse_",
		},
		{
			name: "Anam Ansari",
			bio: "Saganize",
			img: anama,
			twitter: "https://twitter.com/anamansari062",
		},
		{
			name: "Harpalsinh Jadeja",
			bio: "Celo Foundation",
			img: harpalj,
			twitter: "https://twitter.com/HarpalJadeja11",
		},

		{
			name: "Pranshu Rastogi",
			bio: "Push Protocol",
			img: pranshur,
			twitter: "https://twitter.com/pranshu3196",
		},
		{
			name: "Khushboo Verma",
			bio: "Appwrite",
			img: khushboov,
			twitter: "https://twitter.com/khushbooverma_",
		},

		{
			name: "Yash Jagtap",
			bio: "The Graph",
			img: yashj,
			twitter: "https://twitter.com/0x_yasshhh_",
		},
		{
			name: "Simarpreet Singh",
			bio: "Tezos India | Web3Panjab",
			img: simarpreets,
			twitter: "https://twitter.com/simarpreet_019",
		},
		{
			name: "Venkatesh Kanchanpally",
			bio: "Ex-Nethermind",
			img: venkateshk,
			twitter: "https://twitter.com/thankseve",
		},
		{
			name: "Shatabarto Bhattacharya",
			bio: "Timeswap",
			img: shatabarto,
			twitter: "https://twitter.com/riksucks",
		},
		// {
		// 	name: "Aryan Agarwal",
		// 	bio: "SWE @Lenspost",
		// 	img: aryan,
		// 	twitter: "https://twitter.com/_0xaryan",
		// },
		{
			name: "Harsh Sachaniya",
			bio: "LensPlay",
			img: harshs,
			twitter: "https://twitter.com/hrs_2220",
		},
		{
			name: "Rachit Manchanda",
			bio: "Samudai",
			img: rachitm,
			twitter: "https://twitter.com/rachitxdesign/",
		},
		{
			name: "Salil Naik",
			bio: "Socket Technology",
			img: saliln,
			twitter: "https://twitter.com/0xSalilNaik",
		},
		{
			name: "Ujwal Sutaria",
			bio: "TDV Partners",
			img: ujwals,
			twitter: "https://twitter.com/ujwal11us",
		},
		// {
		// 	name: "K Srinivas Rao",
		// 	bio: "Tweeny Technologies",
		// 	img: srinivasr,
		// 	twitter: "https://twitter.com/Srinu53168",
		// },
		{
			name: "Shruti Jain",
			bio: "Neon EVM",
			img: shrutij,
			twitter: "https://twitter.com/shrutijainsj01",
		},
		{
			name: "Rishabh Gupta",
			bio: "Banana SDK",
			img: risho,
			twitter: "https://twitter.com/rishotics",
		},
		{
			name: "Sankalp Pandey",
			bio: "SolidityScan",
			img: sankalpp,
			twitter: "https://twitter.com/SKY_Sankalp",
		},

		{
			name: "Viraz Malhotra",
			bio: "Shell Protocol",
			img: virajm,
			twitter: "https://twitter.com/Viraz04",
		},

		{
			name: "Sandeep Singh",
			bio: "Koii Network",
			img: sandeeps,
			twitter: "https://twitter.com/sandybcsald",
		},
		{
			name: "Aditya Chaudhary",
			bio: "Cordify",
			img: adityac,
			twitter: "https://twitter.com/ItsAditya_xyz",
		},
		{
			name: "Pradumna V Saraf",
			bio: "Livestyle",
			img: pradhumnas,
			twitter: "https://twitter.com/pradumna_saraf",
		},
		{
			name: "Shubham Patel",
			bio: "EthIndia Fellow",
			img: shubhamp,
			twitter: "https://twitter.com/aeyshubh",
		},
		{
			name: "Keyur Jayeshkumar Talati",
			bio: "Firecompass",
			img: keyurt,
			twitter: "https://twitter.com/TalatiKeyur",
		},
		// {
		// 	name: "Swarnabha Sinha",
		// 	bio: "ChainIDE",
		// 	img: swarnabhas,
		// 	twitter: "https://twitter.com/SinhaSwarnabha",
		// },

		{
			name: "Satheesh Paddolker",
			bio: "Kratos Innovation Labs",
			img: satheeshk,
			twitter: "https://twitter.com/paddolker",
		},
		{
			name: "Sandipan Kundu",
			bio: "Shardeum",
			img: sandipank,
			twitter: "https://twitter.com/SandipanKundu42",
		},

		{
			name: "Mohit Bhat",
			bio: "Singularity Singapore",
			img: mohitb,
			twitter: "https://www.twitter.com/mbcse50",
		},
		{
			name: "Pakam Joel Johnson",
			bio: "ZualaCapital",
			img: joelj,
			twitter:
				"https://twitter.com/pakamjoel?t=inLFrBjRF7WGzMmTFGfwvw&s=09",
		},

		{
			name: "Nitanshu Lokhande",
			bio: "Banana SDK",
			img: nitanshul,
			twitter: "https://twitter.com/NitanshuL",
		},
		{
			name: "Aviral Gupta",
			bio: "Plants and Pillars",
			img: aviral,
			twitter: "https://twitter.com/aviral10x",
		},
		{
			name: "Kumarguru T",
			bio: "Status.im",
			img: guru,
			twitter: "http://twitter.com/hackyguru",
		},

		{
			name: "Yax Sheth",
			bio: "Entice Coin",
			img: yaxs,
			twitter: "https://twitter.com/yax22",
		},
		{
			name: "Zishan Sami",
			bio: "NiftyApes",
			img: zishans,
			twitter: "https://twitter.com/zishansami102",
		},
		// {
		// 	name: "Shabbir Khan",
		// 	bio: "Securr (Securr.tech)",
		// 	img: shabbirk,
		// 	twitter: "https://twitter.com/shabbiryk",
		// },
		// {
		// 	name: "Akansha Jain",
		// 	bio: "DZap",
		// 	img: akanshaj,
		// 	twitter: "https://twitter.com/Akanshajain05",
		// },
		{
			name: "Divyanshu Urmaliya",
			bio: "Metacrafters: Proof of Learn",
			img: divyanshu,
			twitter: "https://twitter.com/Divyanshueth",
		},
		{
			name: "Rachit Magon",
			bio: "AirLyft.One",
			img: rachitma,
			twitter: "https://twitter.com/rachitmagon",
		},
		{
			name: "Arbaz Hussain",
			bio: "Immunefi",
			img: arbazh,
			twitter: "https://twitter.com/ArbazKiraak",
		},

		// {
		// 	name: "Himanshu Bisht",
		// 	bio: "Afleet",
		// 	img: himanshub,
		// 	twitter: "https://twitter.com/withhimu",
		// },
		{
			name: "Vivek Pal",
			bio: "ShastraOS",
			img: vivekp,
			twitter: "https://twitter.com/vivekpal0x",
		},
		{
			name: "Aditya Venkat",
			bio: "Metastart",
			img: adityav,
			twitter: "https://twitter.com/0xvenkat",
		},
		{
			name: "Sushrut Mishra",
			bio: "VCMedia Labs",
			img: sushrut,
			twitter: "https://twitter.com/sushrutkm",
		},
	];

	return (
		<>
			<Heading className="font-primary">Substage Speakers</Heading>
			<Wrapper>
				<Marquee
					pauseOnHover
					gradient={false}
					speed={50}
					className="flex items-center h-80 min-w-full bg-gradient-to-r from-transparent to-transparent via-violet-100/10 backdrop-blur-lg"
				>
					{speakersInfo.map((speaker, index) => {
						return (
							<Card key={index} className="shadow-xl">
								<Img src={speaker.img} />
								{speaker.twitter.includes("twitter") ? (
									<Twitter
										href={speaker.twitter}
										target="_blank"
									>
										<svg
											className="w-6 h-6 transition-colors fill-white hover:fill-sky-400"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
										</svg>
									</Twitter>
								) : (
									<Twitter
										href={speaker.twitter}
										target="_blank"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="w-6 h-6 transition-colors fill-white hover:fill-blue-400"
											viewBox="0 0 24 24"
										>
											<path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"></path>
										</svg>
									</Twitter>
								)}
								<Backdrop />
								<Name>{speaker.name}</Name>
								<Bio>{speaker.bio}</Bio>
							</Card>
						);
					})}
				</Marquee>
			</Wrapper>
		</>
	);
};

export default Speaker;
