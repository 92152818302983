import React from "react";
import styled from "styled-components";

import location1 from "../assets/glimpses/location-1.webp";
import location2 from "../assets/glimpses/location-2.webp";
import location3 from "../assets/glimpses/location-3.webp";

import attendees1 from "../assets/glimpses/attendees-1.png";
import attendees2 from "../assets/glimpses/attendees-2.png";
import attendees3 from "../assets/glimpses/attendees-3.png";

import speaker1 from "../assets/glimpses/speaker-1.png";
import speaker2 from "../assets/glimpses/speaker-2.png";
import speaker3 from "../assets/glimpses/speaker-3.png";

import twitter1 from "../assets/glimpses/twitter-1.png";
import twitter2 from "../assets/glimpses/twitter-2.png";
import twitter3 from "../assets/glimpses/twitter-3.png";

interface ImageDivProps {
	type?: string;
}

const ImageSlider = styled.div`
	display: flex;
	grid-template-columns: repeat(auto-fit, minmax(300px, 25%));
	width: 100%;
	margin: 3rem 0;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	@media (max-width: 768px) {
		margin-bottom: 0;
	}
`;

const ImageDiv = styled.div<ImageDivProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 300px;
	width: 25%;
	min-width: 300px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	overflow: hidden;
	position: relative;
	background: linear-gradient(135deg, transparent 33%, rgba(0, 0, 0, 0.33));
	text-align: center;
	line-height: 1;
	padding: ${props => (props.type === "text" ? `1.25rem` : `none`)};
	&:hover .backdrop {
		opacity: 1;
	}
	&:hover img {
		opacity: 1;
		scale: 1.1;
	}
	@media (max-width: 768px) {
		width: 100%;
	}
`;

const Backdrop = styled.div`
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
	z-index: 2;
	opacity: 0;
	pointer-events: none;
	transition: opacity 1s;
`;

const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
	opacity: 0.3;
	transition: all 1s;
`;

const Text1 = styled.div`
	color: white;
	font-size: 84px;
	font-family: "Madeia", "Outfit", "Avenir", "sans-serif";
`;

const Text2 = styled.span`
	font-family: "Recoleta", "Outfit", "Avenir", "sans-serif";
	position: absolute;
	bottom: 0px;
	left: 2%;
	padding: 1rem;
	color: var(--text-100);
	font-size: 20px;
	font-weight: 600;
	z-index: 3;
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 0 0 4rem 0;
	color: var(--text-100);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Memories = () => {
	return (
		<div>
			<Heading className="font-primary">Last Year's Glimpses</Heading>
			<ImageSlider style={{ marginTop: "3rem" }}>
				<ImageDiv type="text">
					<Text1>Novotel Goa</Text1>
					<Text2>Location</Text2>
				</ImageDiv>
				<ImageDiv>
					<Image src={location1} />
					<Text2>5-Star Resort</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
				<ImageDiv>
					<Image src={location2} />
					<Text2>Amazing Vibes</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
				<ImageDiv>
					<Image src={location3} />
					<Text2>Private Beach</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>

				<ImageDiv type="text">
					<Text1>1500+</Text1>
					<Text2>Attendees</Text2>
				</ImageDiv>
				<ImageDiv>
					<Image src={attendees1} />
					<Text2>Main Hall Attendance</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
				<ImageDiv>
					<Image src={attendees2} />
					<Text2>Cruise Kickoff Party</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
				<ImageDiv>
					<Image src={attendees3} />
					<Text2>Daily Indoor Parties</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>

				<ImageDiv type="text">
					<Text1>50+</Text1>
					<Text2>Sessions</Text2>
				</ImageDiv>
				<ImageDiv>
					<Image src={speaker1} />
					<Text2>Amazing Talks</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
				<ImageDiv>
					<Image src={speaker2} />
					<Text2>Crazy Workshops</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
				<ImageDiv>
					<Image src={speaker3} />
					<Text2>Insightful Panels</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>

				<ImageDiv type="text">
					<Text1>10K+</Text1>
					<Text2>Twitter Fam</Text2>
				</ImageDiv>
				<ImageDiv>
					<Image src={twitter1} />
					<Text2>Celebrations</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
				<ImageDiv>
					<Image src={twitter2} />
					<Text2>Happiness</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
				<ImageDiv>
					<Image src={twitter3} />
					<Text2>Celebrity Mentions</Text2>
					<Backdrop className="backdrop" />
				</ImageDiv>
			</ImageSlider>
		</div>
	);
};

export default Memories;
