import styled from "styled-components";

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 2rem 0;
	padding-top: 0.66rem;
	color: var(--text-100);
	@media (max-width: 768px) {
		margin: 3rem 0;
	}
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

interface SponsorsProps {
	[key: string]: {
		data: Array<{
			name: string;
			image: string;
			link: string;
		}>;
		type?: string;
	};
}

const mainSponsors: SponsorsProps = {
	"Brought-To-You-By": {
		data: [
			{
				name: "Girlscript",
				image: "https://res.cloudinary.com/boidu/image/upload/v1690106619/girlscript_full_1_dvwamz.png",
				link: "https://girlscript.tech/",
			},
		],
		type: "Girlscript",
	},
	"Powered By": {
		data: [
			{
				name: "XDC Network",
				image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/y6gcwxtu998if99nmisz",
				link: "https://xinfin.org",
			},
		],
		type: "Main",
	},
};

const hackathonSponsors: SponsorsProps = {
	"Hackathon-Title": {
		data: [
			{
				name: "Solidity Scan",
				image: "https://res.cloudinary.com/boidu/image/upload/c_scale,w_400/v1689073176/solidity%20scan.svg",
				link: "https://solidityscan.com/",
			},
		],
		type: "Hackathon",
	},
	Hackathon: {
		data: [
			{
				name: "PurplePay",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689491830/purplepay.png",
				link: "https://purplepay.app/",
			},
		],
		type: "Hackathon",
	},
	"Hackathon-Platform": {
		data: [
			{
				name: "Devfolio",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689059155/devfolio.png",
				link: "https://devfolio.co/",
			},
		],
		type: "Hackathon",
	},
};

const partners: SponsorsProps = {
	"Ticketing Partner": {
		data: [
			{
				name: "Copperx",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689590182/copperx.svg",
				link: "https://copperx.io/",
			},
		],
		type: "Ticket",
	},
	Partner: {
		data: [
			{
				name: "Internet Computer",
				image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/jz04tgbarxdqx92nrv0y",
				link: "https://internetcomputer.org/",
			},
			{
				name: "Crewsphere",
				image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/ixrx5bld8fal4tzhvnlx",
				link: "https://crewsphere.io/",
			},
		],
	},
};

const otherSponsors: SponsorsProps = {
	"Community Design": {
		data: [
			{
				name: "Threeway Studio",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689672949/ThreeWay.png",
				link: "https://threeway.studio/",
			},
		],
	},
	"Community-Track": {
		data: [
			{
				name: "IQ Wiki",
				image: "https://res.cloudinary.com/boidu/image/upload/v1690107252/logoiqoriginal_kkb3ie.svg",
				link: "https://iq.wiki/",
			},
			{
				name: "DappLooker",
				image: "https://res.cloudinary.com/boidu/image/upload/v1691601637/dapplooker-cropped_hervhg.svg",
				link: "https://dapplooker.com/",
			},
		],
	},
	Venue: {
		data: [
			{
				name: "Novotel",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689673762/Novotel.png",
				link: "https://novotel.accor.com/a/en/india.html",
			},
		],
	},
	Accommodation: {
		data: [
			{
				name: "buktrip",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689931838/buktrip.svg",
				link: "https://www.buktrips.com/hotels/?utm_source=web&utm_medium=online&utm_campaign=web3conf_partnership",
			},
		],
	},
};

const sponsors: SponsorsProps = {
	Silver: {
		data: [
			{
				name: "Google",
				image: "https://res.cloudinary.com/dtqix6um6/image/upload/v1691771510/lockup_GoogleCloud_Reverse_rgb_2900x512px_exyux4.png",
				link: "https://cloud.google.com/",
			},
			{
				name: "QuillAudits",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689058720/quillaudits.png",
				link: "https://quillaudits.com/",
			},
			{
				name: "WalletConnect",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689058730/walletconnect.svg",
				link: "https://walletconnect.org/",
			},
			{
				name: "DYDX Foundation",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689443606/dydx.svg",
				link: "https://twitter.com/dYdXIndia",
			},
			{
				name: "Davos Protocol",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689693007/davos.svg",
				link: "https://davos.xyz/",
			},
			{
				name: "Antier",
				image: "https://res.cloudinary.com/boidu/image/upload/r_30/v1689930775/antr.png",
				link: "https://www.antiersolutions.com/",
			},
			{
				name: "CoinEx",
				image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/skjf5yqesuferopyhcdn",
				link: "https://www.coinex.com/",
			},
		],
		type: "Main",
	},
	Bronze: {
		data: [
			{
				name: "OKX",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689930146/okx.png",
				link: "https://www.okx.com/",
			},
			{
				name: "ArweaveHub",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689967379/arweave_logo_w_nopbof.png",
				link: "https://arweavehub.com/",
			},
			{
				name: "Fantazy One",
				image: "https://res.cloudinary.com/boidu/image/upload/v1689756507/FantazyOne.png",
				link: "https://fantazy.one/",
			},
			{
				name: "Brickstreet",
				image: "https://res.cloudinary.com/boidu/image/upload/c_scale,h_40/v1689930335/brickstreet.avif",
				link: "https://brickstreet.shop/",
			},
			{
				name: "Flipster",
				image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/iorvq6eyredmjdsmzjdq",
				link: "https://flipster.xyz/",
			},
		],
		type: "Main",
	},
};

const supportSponsors: SponsorsProps = {
	"Supported-By": {
		data: [
			{
				name: "Web3 Meetup",
				image: "https://res.cloudinary.com/boidu/image/upload/v1690107434/w3m_wdfk1n.png",
				link: "https://twitter.com/Web3MeetIn",
			},
			{
				name: "Hyperedge",
				image: "https://res.cloudinary.com/boidu/image/upload/v1690107142/Hyperedge_Logo_t29tic.png",
				link: "https://hyperedge.so/",
			},
		],
		type: "Support",
	},
};

const communityPartners = [
	{
		name: "Metaverse Space",
		link: "https://twitter.com/Metavers_space_?t=TMffRoe-xmd0Ku-9txzDQA&s=09",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/jf5stoqlvt3wg6sj5fg8",
	},
	{
		name: "Chennai Web3 Meetups",
		link: "https://twitter.com/web3chennai",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/udonq5rfs6zcdeulkytt",
	},
	{
		name: "Web3 Assam",
		link: "https://twitter.com/web3assam",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/erlavfnvdh1pnlvgayun",
	},
	{
		name: "MetaSky",
		link: "https://twitter.com/Metasky_HQ",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/am3fibmnyryclyzszbcf",
	},
	{
		name: "AltCryptoGems",
		link: "https://twitter.com/altcryptogems",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/ixykmgfv6vhx09tsen79",
	},
	{
		name: "Hydrabad DAO",
		link: "https://twitter.com/hyderabaddao",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/dfm3byhs6yidzjmb1ihh",
	},
	{
		name: "Crypto Waley",
		link: "https://twitter.com/cryptowaley?t=feeiaY8WMoTtkNthMjhjKA&s=09",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/j63xuasuxvdfrygsebbv",
	},
	{
		name: "Tribe Academy",
		link: "https://twitter.com/tribeacademy_in",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/rbotnmslr3fettrs5qnr",
	},
	{
		name: "ActualOne Protocol",
		link: "https://twitter.com/ActualOnexyz",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/o7kunmutlgr8vfemwopp",
	},
	{
		name: "Blockchained India",
		link: "https://twitter.com/blockchainedind",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/bixaohwcwocrhpmphauj",
	},
	{
		name: "Detect Box",
		link: "https://twitter.com/unsnarl_secure?t=Raq2Ss7VBRVjEWmzwHpFag&s=09",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/iczj5e92pgobavnchb1m",
	},
	{
		name: "Blockchain Club SRM",
		link: "https://twitter.com/BlockchainSRM",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/moqifpy1yhisa2r4imsa",
	},
	{
		name: "Crypto Female",
		link: "https://twitter.com/cryptofemale_?s=11&t=focTbqlu_nlWiKUQeDKZ0g",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/d8bbfgayjze59cpjspbu",
	},
	{
		name: "Blockchain NPH",
		link: "https://twitter.com/blockchainnph",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/aalvlzwgddif9yx7i3nz",
	},
	{
		name: "Macha",
		link: "https://twitter.com/Macha0x",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/lpwjdxkcxijehvi2e85b",
	},
	{
		name: "Namma Blockchain",
		link: "https://twitter.com/Our_Blockchain",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/ed7ifrivivkcnvnm5ewd",
	},
	{
		name: "Web3 Connect Global",
		link: "https://www.linkedin.com/company/web3connectglobal/",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/ccasoz2sqbgrimkpordq",
	},
	{
		name: "H.E.R. DAO India",
		link: "https://twitter.com/HERDAOIndia",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/novjhlx8j3j7ko6e0cia",
	},
	{
		name: "Web3Panjab",
		link: "https://twitter.com/web3panjab",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/iq54kv3dxyrt2bv1lblm",
	},
	{
		name: "Social3",
		link: "https://twitter.com/Social3Club",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/x4mnmsmlt7w41bfirokj",
	},
	{
		name: "Web3Kerala",
		link: "https://twitter.com/Web3_kerala",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/lf1nsc6qhii3tm9etp7g",
	},
	{
		name: "Dapps.co",
		link: "https://twitter.com/dapps_co",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/cets6uphjwd3qydtmp1q",
	},
	{
		name: "AirLyft",
		link: "https://twitter.com/airlyftone",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/xei6dxvug3vbnbwdjdkf",
	},
	{
		name: "Tanukiverse",
		link: "https://twitter.com/tanukiverse_hq",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/dnbyzmy5zp3mmpgeoovm",
	},
];

const mediaPartners = [
	{
		name: "Cointelegraph",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/e6pjbioldgjosk0nc9cu",
	},
	{
		name: "CoinPedia",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/qgcpmqafjwir5g85rdmp",
	},
	{
		name: "U Today",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/pmaqytmc4uugeasxcszv",
	},
	{
		name: "Doodhwala",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/cyugv5j6xll5ri5fsecm",
	},
	{
		name: "The News Crypto",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/lrqncti9asr4eujrr5fq",
	},
	{
		name: "The Blockopedia",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Community%20Partner%20Logos/bmtlfsyd0ngomalptyx1",
	},
	{
		name: "Bitcoin world",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/q2ktong0iiaczrckx8wr",
	},
	{
		name: "forwebthree",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/qpm6prusqx6pwtjyizmd",
	},
	{
		name: "Blockwee",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/ck447avdpxxry5ku3lke",
	},
	{
		name: "Coins Capture",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/h853qoeypmjsl4apvp8v",
	},
	{
		name: "The Coin Weekly",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/dchw4q9tiojfqgoxcuhp",
	},
	{
		name: "CryptoniteUae",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/npsxe4tzthdjj6w46b9f",
	},
	{
		name: "Coin Gabbar",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/ntsc1lbzfujsmvhlkjiu",
	},
	{
		name: "Block Tides",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/v1/Media%20Partner%20Logos/ymjo2lsmqjitqdaspdyb",
	},
	{
		name: "Fact Protocol",
		image: "https://res.cloudinary.com/dtqix6um6/image/upload/f_auto,q_auto/eibm6go8jyrmdwc6pmok",
	},
];

const gamingPartner: SponsorsProps = {
	"Payments-&-Gaming-Blockchain": {
		data: [
			{
				name: "Nordek",
				image: "https://res.cloudinary.com/boidu/image/upload/v1691601862/64aeabca2143b897bf3d03e3_MK-1_luq2fj.png",
				link: "https://www.nordek.io",
			},
		],
		type: "Gaming",
	},
};

const typeToIconMap = {
	Main: (
		<path
			fillRule="evenodd"
			d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
			clipRule="evenodd"
		/>
	),
	Hackathon: (
		<path
			fillRule="evenodd"
			d="M14.447 3.027a.75.75 0 01.527.92l-4.5 16.5a.75.75 0 01-1.448-.394l4.5-16.5a.75.75 0 01.921-.526zM16.72 6.22a.75.75 0 011.06 0l5.25 5.25a.75.75 0 010 1.06l-5.25 5.25a.75.75 0 11-1.06-1.06L21.44 12l-4.72-4.72a.75.75 0 010-1.06zm-9.44 0a.75.75 0 010 1.06L2.56 12l4.72 4.72a.75.75 0 11-1.06 1.06L.97 12.53a.75.75 0 010-1.06l5.25-5.25a.75.75 0 011.06 0z"
			clipRule="evenodd"
		/>
	),
	Community: (
		<>
			<path
				fillRule="evenodd"
				d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
				clipRule="evenodd"
			/>
			<path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
		</>
	),
	Media: (
		<>
			<path d="M4.5 4.5a3 3 0 00-3 3v9a3 3 0 003 3h8.25a3 3 0 003-3v-9a3 3 0 00-3-3H4.5zM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06z" />
		</>
	),
	Ticket: (
		<path
			fillRule="evenodd"
			d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 01-.375.65 2.249 2.249 0 000 3.898.75.75 0 01.375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 17.625v-3.026a.75.75 0 01.374-.65 2.249 2.249 0 000-3.898.75.75 0 01-.374-.65V6.375zm15-1.125a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V6a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0v.75a.75.75 0 001.5 0v-.75zm-.75 3a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-.75zM6 12a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H6.75A.75.75 0 016 12zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
			clipRule="evenodd"
		/>
	),
	Girlscript: (
		<path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
	),
	Support: (
		<path
			fillRule="evenodd"
			d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
			clipRule="evenodd"
		/>
	),
	Gaming: (
		<path
			fill="currentColor"
			d="M17 4a6 6 0 0 1 6 6v4a6 6 0 0 1-6 6H7a6 6 0 0 1-6-6v-4a6 6 0 0 1 6-6h10Zm-7 5H8v2H6v2h1.999L8 15h2l-.001-2H12v-2h-2V9Zm8 4h-2v2h2v-2Zm-2-4h-2v2h2V9Z"
		/>
	),
};

const Sponsors = () => {
	return (
		<div>
			<Heading id="sponsors" className="font-primary">
				Sponsors & Partners
			</Heading>
			<div className="flex flex-col items-center justify-center px-0 overflow-hidden mt-14 md:px-8">
				{Object.keys(mainSponsors).map(item => (
					<div className="w-fit">
						<h1 className="sponsor-heading">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-6 h-6 text-yellow-300/75"
							>
								{
									typeToIconMap[
										mainSponsors[
											item as keyof typeof mainSponsors
										].type as keyof typeof typeToIconMap
									]
								}
							</svg>

							<span
								className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim`}
							>
								{item.replaceAll("-", " ")}
							</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-6 h-6 text-yellow-300/75"
							>
								{
									typeToIconMap[
										mainSponsors[
											item as keyof typeof mainSponsors
										].type as keyof typeof typeToIconMap
									]
								}
							</svg>
						</h1>
						<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
							{mainSponsors[
								item as keyof typeof mainSponsors
							].data.map(sponsor => (
								<a
									href={sponsor.link}
									target="_blank"
									rel="noopener noreferrer"
									className="flex items-center justify-center h-32 p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl"
								>
									<img
										src={sponsor.image}
										alt={sponsor.name}
										className="max-h-[4rem] min-w-[10rem] md:min-w-[16rem] object-contain"
									/>
								</a>
							))}
						</div>
					</div>
				))}
				{Object.keys(gamingPartner).map(item => (
					<div className="w-fit">
						<h1 className="sponsor-heading">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-6 h-6 text-yellow-300/75"
							>
								{
									typeToIconMap[
										gamingPartner[
											item as keyof typeof gamingPartner
										].type as keyof typeof typeToIconMap
									]
								}
							</svg>

							<span
								className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim max-w-[210px] md:max-w-[260px]`}
							>
								{item.replaceAll("-", " ")} Partner
							</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-6 h-6 text-yellow-300/75"
							>
								{
									typeToIconMap[
										gamingPartner[
											item as keyof typeof gamingPartner
										].type as keyof typeof typeToIconMap
									]
								}
							</svg>
						</h1>
						<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
							{gamingPartner[
								item as keyof typeof gamingPartner
							].data.map(sponsor => (
								<a
									href={sponsor.link}
									target="_blank"
									rel="noopener noreferrer"
									className="flex items-center justify-center h-32 p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl"
								>
									<img
										width={180}
										src={sponsor.image}
										alt={sponsor.name}
										// className="max-h-[4rem] min-w-[10rem] md:min-w-[16rem] object-contain"
									/>
								</a>
							))}
						</div>
					</div>
				))}
				{Object.keys(sponsors).map(item => (
					<div className="pt-2 w-fit">
						<h1 className="sponsor-heading">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-6 h-6 text-yellow-300/75"
							>
								{
									typeToIconMap[
										sponsors[item as keyof typeof sponsors]
											.type as keyof typeof typeToIconMap
									]
								}
							</svg>

							<span
								className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim`}
							>
								{item.replace("-", " ")} Partner
								{sponsors[item as keyof typeof sponsors].data
									.length > 1
									? "s"
									: ""}
							</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-6 h-6 text-yellow-300/75"
							>
								{
									typeToIconMap[
										sponsors[item as keyof typeof sponsors]
											.type as keyof typeof typeToIconMap
									]
								}
							</svg>
						</h1>
						<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
							{sponsors[item as keyof typeof sponsors].data.map(
								sponsor => (
									<a
										href={sponsor.link}
										target="_blank"
										rel="noopener noreferrer"
										className="flex items-center justify-center h-32 p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl"
									>
										<img
											src={sponsor.image}
											alt={sponsor.name}
											className="max-h-[4rem] min-w-[10rem] md:min-w-[16rem] object-contain"
										/>
									</a>
								)
							)}
						</div>
					</div>
				))}
				<div className="flex flex-wrap justify-center">
					{Object.keys(partners).map(item => (
						<div className="w-fit">
							<h1 className="sponsor-heading">
								{partners[item].type && (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										className="w-6 h-6 text-yellow-300/75"
									>
										{
											typeToIconMap[
												partners[
													item as keyof typeof partners
												]
													.type as keyof typeof typeToIconMap
											]
										}
									</svg>
								)}

								<span
									className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim`}
								>
									{item}
								</span>
								{partners[item].type && (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										className="w-6 h-6 text-yellow-300/75"
									>
										{
											typeToIconMap[
												partners[
													item as keyof typeof partners
												]
													.type as keyof typeof typeToIconMap
											]
										}
									</svg>
								)}
							</h1>
							<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
								{partners[
									item as keyof typeof partners
								].data.map(sponsor => (
									<a
										href={sponsor.link}
										target="_blank"
										rel="noopener noreferrer"
										className="flex items-center justify-center h-32 p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl"
									>
										<img
											src={sponsor.image}
											alt={sponsor.name}
											className="max-h-[4rem] min-w-[10rem] md:min-w-[16rem] object-contain"
										/>
									</a>
								))}
							</div>
						</div>
					))}
				</div>
				<div className="flex flex-wrap justify-center">
					{Object.keys(hackathonSponsors).map(item => (
						<div className="w-fit">
							<h1 className="sponsor-heading">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="w-6 h-6 text-yellow-300/75"
								>
									{
										typeToIconMap[
											hackathonSponsors[
												item as keyof typeof hackathonSponsors
											].type as keyof typeof typeToIconMap
										]
									}
								</svg>

								<span
									className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim`}
								>
									{item.replace("-", " ")}{" "}
									{item === "Hackathon-Title"
										? "Sponsor"
										: "Partner"}
									{hackathonSponsors[
										item as keyof typeof hackathonSponsors
									].data.length > 1
										? "s"
										: ""}
								</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="w-6 h-6 text-yellow-300/75"
								>
									{
										typeToIconMap[
											hackathonSponsors[
												item as keyof typeof hackathonSponsors
											].type as keyof typeof typeToIconMap
										]
									}
								</svg>
							</h1>
							<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
								{hackathonSponsors[
									item as keyof typeof hackathonSponsors
								].data.map(sponsor => (
									<a
										href={sponsor.link}
										target="_blank"
										rel="noopener noreferrer"
										className="flex items-center justify-center h-32 p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl"
									>
										<img
											src={sponsor.image}
											alt={sponsor.name}
											className="max-h-[4rem] min-w-[10rem] md:min-w-[16rem] object-contain"
										/>
									</a>
								))}
							</div>
						</div>
					))}
				</div>
				<div className="flex flex-wrap justify-center ">
					{Object.keys(otherSponsors).map(item => (
						<div className="w-fit">
							<h1 className="sponsor-heading">
								{otherSponsors[
									item as keyof typeof otherSponsors
								].type && (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										className="w-6 h-6 text-yellow-300/75"
									>
										{
											typeToIconMap[
												otherSponsors[
													item as keyof typeof otherSponsors
												]
													.type as keyof typeof typeToIconMap
											]
										}
									</svg>
								)}

								<span
									className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim`}
								>
									{item.replace("-", " ")}{" "}
									{item === "Community-Track"
										? `Sponsor`
										: `Partner`}
									{otherSponsors[
										item as keyof typeof otherSponsors
									].data.length > 1
										? "s"
										: ""}
								</span>
								{otherSponsors[
									item as keyof typeof otherSponsors
								].type && (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										className="w-6 h-6 text-yellow-300/75"
									>
										{
											typeToIconMap[
												otherSponsors[
													item as keyof typeof otherSponsors
												]
													.type as keyof typeof typeToIconMap
											]
										}
									</svg>
								)}
							</h1>
							<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
								{otherSponsors[
									item as keyof typeof otherSponsors
								].data.map(sponsor => (
									<a
										href={sponsor.link}
										target="_blank"
										rel="noopener noreferrer"
										className="p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl"
									>
										<img
											src={sponsor.image}
											alt={sponsor.name}
											className="max-h-[4rem] min-w-[10rem] md:min-w-[16rem] object-contain"
										/>
									</a>
								))}
							</div>
						</div>
					))}
				</div>
				<h1 className="sponsor-heading">
					<span
						className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim`}
					>
						Community Partners
					</span>
				</h1>
				<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
					{communityPartners.map(community => (
						<a
							href={community.link}
							target="_blank"
							rel="noopener noreferrer"
							className="flex items-center justify-center h-32 p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl"
						>
							<img
								src={community.image}
								alt={community.name}
								className="max-h-[4rem] object-contain"
							/>
						</a>
					))}
				</div>
				<h1 className="sponsor-heading">
					<span
						className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim`}
					>
						Media Partners
					</span>
				</h1>
				<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
					{mediaPartners.map(partner => (
						<div className="flex items-center justify-center h-32 p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl">
							<img
								src={partner.image}
								alt={partner.name}
								className="max-h-[4rem] object-contain"
							/>
						</div>
					))}
				</div>
				<div className="flex flex-wrap items-center justify-center mb-8">
					{Object.keys(supportSponsors).map(item => (
						<div className="pt-2 w-fit">
							<h1 className="sponsor-heading">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="w-6 h-6 text-yellow-300/75"
								>
									{
										typeToIconMap[
											supportSponsors[
												item as keyof typeof supportSponsors
											].type as keyof typeof typeToIconMap
										]
									}
								</svg>

								<span
									className={`md:text-2xl text-xl text-transparent font-secondary sponsor-heading-anim`}
								>
									{item.replaceAll("-", " ")}
								</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="w-6 h-6 text-yellow-300/75"
								>
									{
										typeToIconMap[
											supportSponsors[
												item as keyof typeof supportSponsors
											].type as keyof typeof typeToIconMap
										]
									}
								</svg>
							</h1>
							<div className="flex flex-wrap items-center justify-center mt-4 mb-8">
								{supportSponsors[
									item as keyof typeof supportSponsors
								].data.map(sponsor => (
									<a
										href={sponsor.link}
										target="_blank"
										rel="noopener noreferrer"
										className="flex items-center justify-center h-32 p-8 m-4 transition-all duration-300 cursor-pointer group bg-violet-100/5 rounded-xl backdrop-blur-2xl hover:bg-violet-100/10 hover:shadow-xl"
									>
										<img
											src={sponsor.image}
											alt={sponsor.name}
											className="max-h-[4rem] min-w-[10rem] md:min-w-[16rem] object-contain"
										/>
									</a>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Sponsors;
