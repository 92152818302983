import { useScroll, animated } from "@react-spring/web";
import React from "react";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import dydxLogo from "../assets/2022/dydx.png";
import pandoraLogo from "../assets/2022/Pandora.png";
import filecoinLogo from "../assets/2022/filecoin.png";
import kometLogo from "../assets/2022/Komet.png";
import valistLogo from "../assets/2022/valist.png";
import walletconnectLogo from "../assets/2022/WalletConnect.png";
import alchemyLogo from "../assets/2022/Alchemy.png";
import w3mLogo from "../assets/2022/w3m.png";
import buildersTribe from "../assets/2022/builders_tribe.png";
import stackosLogo from "../assets/2022/StackOS.png";
import superteamLogo from "../assets/2022/superteam.png";
import quillauditsLogo from "../assets/2022/QuillAudits.png";
import developerdaoLogo from "../assets/2022/developer_dao.png";
import zkxLogo from "../assets/2022/zkx.png";
import assetmantleLogo from "../assets/2022/AssetMantle.png";
import buidlspaceLogo from "../assets/2022/buildspace.png";
import lumosLogo from "../assets/2022/lumos-white.png";
import shardeumLogo from "../assets/2022/Shardeum.png";
import coinmarketcapLogo from "../assets/2022/coinmarketcap.png";
import dehiddenLogo from "../assets/2022/Dehidden.png";
import yourstoryLogo from "../assets/2022/Yourstory.png";
import aicgimLogo from "../assets/2022/aic-gim.png";
import sellbuystartupsLogo from "../assets/2022/sell_buy_startups.png";
import incubeesLogo from "../assets/2022/incubees.png";

const Wrapper = styled.div`
	--background: hsl(264, 62%, 44%);
	--background-transparent: hsla(264, 69%, 15%, 0.75);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
`;

const Box = styled.div`
	color: black;
	font-size: 20px;
	font-weight: 800;
	width: 100%;
	margin: 3rem 0;
	margin-bottom: 1rem;
	position: relative;
	@media (max-width: 768px) {
		margin-top: 1rem;
	}
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	color: var(--text-100);
	@media (max-width: 768px) {
		margin: 0 0 2.5rem 0;
	}
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Image = ({
	src,
	alt,
	href,
}: {
	src: string;
	alt: string;
	href: string;
}) => {
	return (
		<a className="mr-12 cursor-pointer" href={href} target="_blank">
			<img
				className="h-8 transition-[filter] duration-300 grayscale hover:grayscale-0"
				src={src}
				alt={alt}
			/>
		</a>
	);
};

const PastSponsors = () => {
	const { scrollYProgress } = useScroll();
	const imageList = [
		{
			src: dydxLogo,
			alt: "dydx",
			href: "https://dydx.foundation/",
		},
		{
			src: pandoraLogo,
			alt: "Pandora",
			href: "https://pandora.finance/",
		},
		{
			src: filecoinLogo,
			alt: "Filecoin",
			href: "https://filecoin.io/",
		},
		{
			src: kometLogo,
			alt: "Komet",
			href: "https://kometdefi.com/",
		},
		{
			src: valistLogo,
			alt: "Valist",
			href: "https://www.valist.io/",
		},
		{
			src: walletconnectLogo,
			alt: "WalletConnect",
			href: "https://walletconnect.com/",
		},
		{
			src: alchemyLogo,
			alt: "Alchemy",
			href: "https://www.alchemy.com/",
		},
		{
			src: w3mLogo,
			alt: "W3M",
			href: "https://twitter.com/web3meetin",
		},
		{
			src: buildersTribe,
			alt: "Builders Tribe",
			href: "https://buidlerstribe.com/",
		},
		{
			src: stackosLogo,
			alt: "StackOS",
			href: "https://stackos.io/",
		},
		{
			src: superteamLogo,
			alt: "SuperTeam",
			href: "https://superteam.fun/",
		},
		{
			src: quillauditsLogo,
			alt: "QuillAudits",
			href: "https://quillaudits.com/",
		},
		{
			src: developerdaoLogo,
			alt: "Developer DAO",
			href: "https://developerdao.com/",
		},
		{
			src: zkxLogo,
			alt: "zkx",
			href: "https://zkx.fi/",
		},
		{
			src: assetmantleLogo,
			alt: "AssetMantle",
			href: "https://www.assetmantle.one/",
		},
		{
			src: buidlspaceLogo,
			alt: "Buidlspace",
			href: "https://buidlspace.com/",
		},
		{
			src: lumosLogo,
			alt: "Lumos",
			href: "https://www.lumoslabs.co/",
		},
		{
			src: shardeumLogo,
			alt: "Shardeum",
			href: "https://shardeum.org/",
		},
		{
			src: coinmarketcapLogo,
			alt: "CoinMarketCap",
			href: "https://coinmarketcap.com/",
		},
		{
			src: dehiddenLogo,
			alt: "Dehidden",
			href: "https://dehidden.com/",
		},
		{
			src: yourstoryLogo,
			alt: "YourStory",
			href: "https://yourstory.com/",
		},
		{
			src: aicgimLogo,
			alt: "AIC GIM",
			href: "https://www.aicgim.in/",
		},
		{
			src: sellbuystartupsLogo,
			alt: "Sell Buy Startups",
			href: "https://sellbuystartups.in/",
		},
		{
			src: incubeesLogo,
			alt: "Incubees",
			href: "https://incubees.com/",
		},
	];
	return (
		<Wrapper>
			<Heading className="font-primary">Past Sponsors / Partners</Heading>
			<Box>
				<Marquee
					gradient={false}
					speed={50}
					className="flex items-center h-24 min-w-full bg-gradient-to-r from-transparent to-transparent via-violet-100/10 backdrop-blur-lg"
				>
					{imageList.map((image, index) => (
						<Image
							key={index}
							src={image.src}
							alt={image.alt}
							href={image.href}
						/>
					))}
				</Marquee>
			</Box>
		</Wrapper>
	);
};

export default PastSponsors;
