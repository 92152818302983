import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 2rem 0 0 0;
	padding-top: 0.66rem;
	color: var(--text-100);
	@media (max-width: 768px) {
		margin: 3rem 0;
	}
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Text = styled.div`
	font-size: 16px;
	width: 80%;
	text-align: justify;
	margin: 2rem 10%;
	padding-top: 0.66rem;
	color: var(--text-100);
	@media (max-width: 768px) {
		margin: 1rem 5%;
		width: 90%;
	}
`;

const About: React.FC = () => {
	const isMobile = useMediaQuery({
		query: "(max-width: 768px)",
	});
	return (
		<>
			<Heading className="font-primary">About Web3Conf India</Heading>
			<div
				style={
					isMobile
						? {
								display: "flex",
								alignItems: "center",
								flexDirection: "column",
						  }
						: {
								display: "flex",
								alignItems: "center",
								flexDirection: "row",
						  }
				}
			>
				<div
					style={
						isMobile
							? { width: "90%" }
							: { width: "75%", marginInline: "auto" }
					}
				>
					<Text>
						<span className="font-bold font-secondary">
							Web3Conf
						</span>{" "}
						is India's first and biggest chain agnostic conference
						by{" "}
						<span className="font-bold font-secondary">
							GirlScript Foundation
						</span>
						. Last year, this conference was conducted around the
						same duration with over 1500+ enthusiastic web3.0 lovers
						in Goa. Along with a Hackathon "Hack - Web3conf", we
						also have Startup Pitch Sessions and Talks and Panel
						discussions as well as company booths.
						<br />
						<br />
						<span className="font-bold font-secondary">
							GirlScript
						</span>{" "}
						is supporting beginners in tech education since 2017
						with programs like{" "}
						<span className="font-bold font-secondary">
							GirlScript Summer of Code, LetsPy, Tech In Slums,
							Uplift Project
						</span>
						, etc. The foundation has impacted{" "}
						<span className="font-bold font-secondary">
							500,000+ learners
						</span>{" "}
						and has been featured in{" "}
						<span className="font-bold font-secondary">
							Forbes Asia, Tedx, Booking.com Technology Playmaker,
							ElasticSearch Cause Awards, Women Tech Network
							Awards, Society of Women Engineers Magazine
						</span>
						, etc, and has worked with over 250+ global tech and
						social good companies like{" "}
						<span className="font-bold font-secondary">
							Microsoft, Redhat, Twitter, Atlassian, Filecoin,
							Polygon, Dapper Labs, Child Rights & You
						</span>
						, etc.
						<br />
						<br />
						The foundation also conducted Web3.0 beginner-friendly
						boot camps in 52 cities in India impacting{" "}
						<span className="font-bold font-secondary">
							15,000+ learners
						</span>
						. To create opportunities with Builders, Organizers,
						Learners, and Entrepreneurs, GirlScript also conducted
						Web3 Villa Meetups via{" "}
						<span className="font-bold font-secondary">
							Web3 Meetup India
						</span>{" "}
						in 10+ cities as well as{" "}
						<span className="font-bold font-secondary">
							International NFT Day
						</span>{" "}
						in 12 cities along with{" "}
						<span className="font-bold font-secondary">
							Flow Hackathon 2023
						</span>{" "}
						with over{" "}
						<span className="font-bold font-secondary">
							7000+ developers
						</span>{" "}
						and{" "}
						<span className="font-bold font-secondary">
							Winter of Blockchain
						</span>{" "}
						with over{" "}
						<span className="font-bold font-secondary">
							4000 developers
						</span>
						.
						<br />
						<br />
						This year, Our team is all set to welcome the 3000+
						Web3.0 enthusiasts in Goa for{" "}
						<span className="font-bold font-secondary">
							Web3conf 2023 🎉
						</span>
					</Text>
				</div>
				{/* <div>
					<div
						style={
							isMobile
								? {
										height: "450px",
										overflowY: "scroll",
										width: "100vw",
										overflowX: "scroll",
								  }
								: {
										height: "450px",
										overflowY: "scroll",
								  }
						}
					>
						<a
							className="twitter-timeline"
							data-theme="dark"
							href="https://twitter.com/web3conf_india?ref_src=twsrc%5Etfw"
						>
							Tweets by web3conf_india
						</a>
					</div>
				</div> */}
			</div>
		</>
	);
};

export default About;
