import { useEffect, useState } from "react";

interface ScrollPosition {
	x: number;
	y: number;
}

function getScrollPosition(): ScrollPosition {
	return typeof window !== "undefined"
		? { x: window.pageXOffset, y: window.pageYOffset }
		: { x: 0, y: 0 };
}

function useWindowEvent<K extends string>(
	type: K,
	listener: K extends keyof WindowEventMap
		? (this: Window, ev: WindowEventMap[K]) => void
		: (this: Window, ev: CustomEvent) => void,
	options?: boolean | AddEventListenerOptions
) {
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.addEventListener(type, listener, options);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return () => window.removeEventListener(type, listener, options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export function useWindowScroll() {
	const [position, setPosition] = useState(getScrollPosition());

	useWindowEvent("scroll", () => setPosition(getScrollPosition()));
	useWindowEvent("resize", () => setPosition(getScrollPosition()));

	return position;
}
