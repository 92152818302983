import React, { useState } from "react";
import styled, { css } from "styled-components";

interface DayProps {
	readonly day: number;
}

const AgendaSlider = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 3rem 0;
	width: 100%;
`;

const AgendaDiv = styled.div`
	width: 90%;
	max-width: 600px;
`;

const stageAgendaHeading = css`
	border: 2px solid var(--border-300);
	border-radius: 16px 16px 0 0;
	margin-top: 2rem;
	overflow: hidden;
	background: rgba(255, 255, 255, 0.025);
	backdrop-filter: blur(10px);
`;

const AgendaHeading = styled.div<{ stage?: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: ${props => !props.stage && `2px solid var(--border-300)`};
	${props => props.stage && stageAgendaHeading}
	width: 100%;
`;

const Day = styled.div<DayProps>`
	font-size: 36px;
	color: var(--text-100);
	padding: 5px 20px;
	padding-top: 0.5rem;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	cursor: pointer;
	transform: translateY(2px);
	width: 100%;
	text-align: center;

	:nth-child(${props => props.day}) {
		color: var(--text-400);
		background: var(--text-100);
	}

	@media (max-width: 768px) {
		width: auto;
		font-size: 28px;
	}
`;

const Stage = styled.div<{ stage: number }>`
	font-size: 24px;
	color: var(--text-100);
	padding: 5px 12px;
	padding-top: 0.5rem;

	cursor: pointer;
	flex-grow: 1;
	text-align: center;

	:nth-child(${props => props.stage}) {
		color: var(--text-400);
		background: var(--text-100);
	}

	@media (max-width: 768px) {
		font-size: 28px;
	}
`;

const AgendaContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border: 2px solid var(--border-300);
	border-top: 0;
	border-radius: 0 0 16px 16px;
	padding: 1rem;
`;

const AgendaRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const Time = styled.span`
	text-align: right;
	width: 38%;
	color: var(--text-500);
	font-size: 16px;
	height: 100%;
	font-family: "Space Mono", monospace;
	letter-spacing: -0.4px;
	margin-top: calc(1rem + 6px);
`;

const Space = styled.span`
	width: 10%;
`;

const Event = styled.span`
	text-align: left;
	color: var(--text-100);
	width: 60%;
	font-weight: 700;
	margin-top: 20px;
	font-size: 20px;
`;

const EventDescription = styled.div`
	color: var(--text-300);
	margin: 2px 0;
	font-size: 16px;
	font-weight: 700;
`;

const EventTalk = styled.div`
	color: var(--text-500);
	margin: 2px 0;
	font-size: 14px;
	font-weight: 100;
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	color: var(--text-100);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Agenda = () => {
	const event: Array<{ [stage: number]: Array<{ [key: string]: string }> }> =
		[
			{
				2: [
					{
						time: "09:00 AM IST",
						name: "City Tour (FCFS)",
						description:
							"One-day city tour for early arrivals on a first come first serve basis",
					},
					{
						time: "07:00 PM IST",
						name: "Opening Party",
						description: "Opening party with a guest performance",
					},
				],
			},
			{
				1: [
					{
						time: "10:10 - 10:30 AM IST",
						name: "Francesco Ciulla",
						description: "daily.dev",
						talk: "Web3 - Decentralized Social MediaWeb3 and Decentralized Social Media",
					},
					{
						time: "10:40 - 11:00 AM IST",
						name: "Raj Kapoor",
						description: "Indian Blockchain Alliance",
						talk: "The Need for an India Web 3 Ecosystem",
					},
					{
						time: "11:10 - 11:30 AM IST",
						name: "Preetam Rao",
						description: "QuillAudits",
						talk: "Staying safe in Web3",
					},
					{
						time: "11:40 - 12:00 PM IST",
						name: "Deep Chenna",
						description: "Google Cloud for Web3",
					},
					{
						time: "12:10 - 12:30 PM IST",
						name: "Satheesh Kumar",
						description: "XDC Network",
						talk: "XDC Network - Secure and scalable blockchain solution",
					},
					{
						time: "12:40 - 01:00 PM IST",
						name: "Tanvi Ratna",
						description: "Policy 4.0",
					},
					{
						time: "01:00 - 02:00 PM IST",
						name: "Brunch + Lunch",
						description:
							"Diversity brunch [invite-only] + lunch for everyone",
					},
					{
						time: "02:10 - 02:30 PM IST",
						name: "Panel discussion",
						description:
							"Strategies for Onboarding 100 Million Indian Users to Web3",
					},
					{
						time: "02:40 - 03:00 PM IST",
						name: "Amit Hardi",
						description: "Indi GG",
					},
					{
						time: "03:10 - 03:30 PM IST",
						name: "Vikram Singh",
						description: "Antier Solutions",
						talk: "New frontiers in web3 to supercharge mass adoption - Taking blockchain to the consumers.",
					},
					{
						time: "03:40 - 04:00 PM IST",
						name: "Kasif Syed",
						description: "CoinEx",
					},
					{
						time: "04:10 - 04:30 PM IST",
						name: "Ravish Jain",
						description: "Ex - Tatum",
						talk: "Superior Techniques: Blockchain Project Development",
					},
					{
						time: "04:40 - 05:00 PM IST",
						name: "Rick Carback",
						description: "XX Network",
					},
					{
						time: "05:00 - 05:30 PM IST",
						name: "Abhay Singh",
						description:
							"Workshop: Simplify Web3 Data with DappLooker",
					},
					{
						time: "07:00 PM IST",
						name: "Live Band Performance",
						description: "",
					},
				],
				2: [
					{
						time: "10:30 - 11:30 AM IST",
						name: "Krishna Upadhyaya",
						description: "Erigon - RPC client for EVM Chains",
					},
					{
						time: "11:45 - 12:15 PM IST",
						name: "Sankalp Pandey",
						description:
							"Addition of Security Layer over the protocols",
					},
					{
						time: "12:30 - 01:00 PM IST",
						name: "Pradumna V Saraf",
						description:
							"Open Up to Open Source: Empowering Sustainable Communities Through Collaboration",
					},
					{
						time: "01:00 - 02:00 PM IST",
						name: "Brunch + Lunch",
						description:
							"Diversity brunch [invite-only] + lunch for everyone",
					},
					{
						time: "02:00 - 02:30 PM IST",
						name: "Pranshu Rastogi",
						description: "Pushing the boundaries of Web3 UX",
					},
					{
						time: "02:45 - 03:15 PM IST",
						name: "Sushrut Mishra",
						description:
							"3Cs: How Developer-Creators are Shaping the Web3 Ecosystem",
					},
					{
						time: "03:30 - 04:00 PM IST",
						name: "Rhic Chowdhury",
						description:
							"Account abstraction - The path from metatransactions to intents",
					},
					{
						time: "04:15 - 04:45 PM IST",
						name: "Vanshika Srivastava",
						description:
							"Implementing Account Abstraction for better web3 UX and dapp experience",
					},
					{
						time: "05:00 - 05:30 PM IST",
						name: "Panel discussion",
						description: "Women in Web3",
					},
					{
						time: "07:00 PM IST",
						name: "Live Band Performance",
						description: "",
					},
				],
				3: [
					{
						time: "10:30 - 11:30 AM IST",
						name: "Shubham Patel",
						description:
							"Building a cross-chain vault using Hyperlane",
					},
					{
						time: "11:45 - 12:15 PM IST",
						name: "Harpalsinh Jadeja",
						description: "ELIF: ETH 2.0",
					},
					{
						time: "12:30 - 01:00 PM IST",
						name: "Shatabarto Bhattacharya",
						description:
							"Fuzz and Invariant Testing, A must for a secure DeFi protocol",
					},
					{
						time: "01:00 - 02:00 PM IST",
						name: "Brunch + Lunch",
						description:
							"Diversity brunch [invite-only] + lunch for everyone",
					},
					{
						time: "02:00 - 02:30 PM IST",
						name: "Harsh Sachaniya",
						description:
							"Building a Decentralized Music App on the Lens Protocol",
					},
					{
						time: "02:45 - 03:15 PM IST",
						name: "Rachit Manchanda",
						description:
							"Designing for Decentralisation : A Complex Problem Statement",
					},
					{
						time: "03:30 - 04:00 PM IST",
						name: "Shruti Jain",
						description:
							"NeonEVM: Bridging the gap between Ethereum and Solana",
					},
					{
						time: "04:15 - 04:45 PM IST",
						name: "Rishabh Gupta",
						description:
							"ZK-based multi-factor authentication for secured smart contract wallets",
					},
					{
						time: "07:00 PM IST",
						name: "Live Band Performance",
						description: "",
					},
				],
			},
			{
				1: [
					{
						time: "10:30 - 11:00 AM IST",
						name: "Ajeet Khurana",
						description: "Reflexical",
					},
					// {
					// 	time: "10:40 - 11:00 AM IST",
					// 	name: "Preetam Rao",
					// 	description: "QuillAudits",
					// },
					{
						time: "11:10 - 11:30 AM IST",
						name: "Anisha Patnaik",
						description: "The Chain Project",
					},
					{
						time: "11:40 - 12:00 PM IST",
						name: "Naveen Saraswat",
						description: "Fantazy One",
						talk: "Why Decentralized Gaming?",
					},
					{
						time: "12:10 - 12:30 PM IST",
						name: "Shusen Yang",
						description: "OKX Wallet",
					},
					{
						time: "12:40 - 01:00 PM IST",
						name: "Panel discussion",
						description: "Raising funds in Web3 Era",
					},

					{
						time: "01:00 - 02:00 PM IST",
						name: "Lunch",
						description: "Lunch for everyone",
					},
					{
						time: "02:10 - 02:30 PM IST",
						name: "Panel discussion",
						description:
							"Multichain Approach: The Future of Blockchain Industry",
					},
					{
						time: "02:40 - 03:00 PM IST",
						name: "Karan Keswani",
						description: "Sandbox",
					},
					{
						time: "03:10 - 03:30 PM IST",
						name: "Dilip Chenoy",
						description: "Bharat Web3 Association",
						talk: "The Present and Future of the Web3 Sector in India",
					},
					{
						time: "03:40 - 04:00 PM IST",
						name: "Varun Satyam",
						description: "Davos",
					},
					{
						time: "04:10 - 04:30 PM IST",
						name: "Pratik Shigli",
						description: "dYdX Foundation",
					},
					{
						time: "04:40 - 05:00 PM IST",
						name: "Mirzad Makhdoom",
						description: "Tribe Academy",
					},
					{
						time: "07:00 PM IST",
						name: "DJ + Cocktail Party",
						description: "",
					},
				],
				2: [
					{
						time: "10:30 - 11:30 AM IST",
						name: "Ujwal Sutaria",
						description: "Insider secrets of startup fundraising",
					},
					{
						time: "11:45 - 12:15 PM IST",
						name: "Sandipan Kundu",
						description:
							"Sharding - A  solution to unlock the next generation of scalable dapps",
					},
					{
						time: "12:30 - 01:00 PM IST",
						name: "Aviral Gupta",
						description:
							"Empowering Climate Action through Gamification and Blockchain Technology",
					},
					{
						time: "01:00 - 02:00 PM IST",
						name: "Lunch",
						description: "Lunch for everyone",
					},
					{
						time: "02:00 - 02:30 PM IST",
						name: "Manav Darji",
						description:
							"Technical Deep Dive into Verkle Trees - Unlocking the potential of state-less execution",
					},
					{
						time: "02:45 - 03:15 PM IST",
						name: "Khushboo Verma",
						description:
							"Web3 Developer Branding: Telling Your Story and Building Your Reputation",
					},
					{
						time: "03:30 - 04:00 PM IST",
						name: "Viraz Malhotra",
						description: "Making DeFi Simple With Ocean & Proteus",
					},
					{
						time: "04:15 - 04:45 PM IST",
						name: "Sandeep Singh",
						description:
							"To Smart Contracts and Beyond, A Brief History of Decentralization",
					},
					{
						time: "05:00 - 05:30 PM IST",
						name: "Panel discussion",
						description: "Future of Web3",
					},
					{
						time: "07:00 PM IST",
						name: "DJ + Cocktail Party",
						description: "",
					},
				],
				3: [
					{
						time: "10:30 - 11:30 AM IST",
						name: "Anam Ansari",
						description:
							"Building Decentralized Mobile Application using Solana",
					},
					{
						time: "11:45 - 12:15 PM IST",
						name: "Keyur Jayeshkumar Talati",
						description:
							"Hack story of Metamask Wallet integrated  web app",
					},
					{
						time: "12:30 - 01:00 PM IST",
						name: "Aditya Chaudhary",
						description:
							"Decentralized Social Network and Why we need it",
					},
					{
						time: "01:00 - 02:00 PM IST",
						name: "Lunch",
						description: "Lunch for everyone",
					},
					{
						time: "02:00 - 02:30 PM IST",
						name: "Mohit Bhat",
						description:
							"Decoding On-Chain Gaming on Blockchain: Practical Insights and Solutions for Game Developers",
					},
					{
						time: "02:45 - 03:15 PM IST",
						name: "Rachit Magon",
						description: "",
					},
					{
						time: "03:30 - 04:00 PM IST",
						name: "Soham Zemse",
						description:
							"ZK Proof of Exploit: How would smart contract hacking look like in the future?",
					},
					{
						time: "04:15 - 04:45 PM IST",
						name: "Nitanshu Lokhande",
						description:
							"Building infra for seamless and secure user onboarding",
					},
					{
						time: "05:00 - 05:30 PM IST",
						name: "Arbaz Hussain",
						description:
							"Navigating EVM Chain Security: Smart contract vulnerabilities",
					},
					{
						time: "07:00 PM IST",
						name: "DJ + Cocktail Party",
						description: "",
					},
				],
			},
			{
				1: [
					{
						time: "11:00 - 11:20 AM IST",
						name: "Divya Mohan",
						description: "Flipkart Labs",
					},
					{
						time: "11:30 - 11:50 AM IST",
						name: "Panel discussion",
						description:
							"Bridging the gap: Web2 & Web3. Fostering collaborative innovation",
					},
					{
						time: "12:00 - 12:20 PM IST",
						name: "Abhydoy Das",
						description: "Bybit",
					},
					{
						time: "12:30 - 12:50 PM IST",
						name: "Ashutosh Datt",
						description: "Arweave",
					},
					{
						time: "01:00 - 02:00 PM IST",
						name: "Lunch",
						description: "Lunch for everyone",
					},
					{
						time: "02:10 - 02:30 PM IST",
						name: "Panel discussion",
						description:
							"Role of Blockchain in the Gaming Industry",
					},
					{
						time: "02:40 - 03:00 PM IST",
						name: "Anupam Varshney",
						description: "Cross border payments and it's usecases",
					},
					{
						time: "03:10 - 03:30 PM IST",
						name: "Parth Chaturvedi",
						description: "CoinSwitch Ventures",
					},
					// {
					// 	time: "03:10 - 03:30 PM IST",
					// 	name: "Dilip Chenoy",
					// 	description: "",
					// },
					// {
					// 	time: "03:40 - 04:00 PM IST",
					// 	name: "Varun Satyam",
					// 	description: "",
					// },
					{
						time: "03:00 - 06:00 PM IST",
						name: "VC Networking",
						description: "",
					},
					{
						time: "07:00 PM IST",
						name: "Cruise After Party",
						description: "",
					},
				],
				2: [
					{
						time: "10:30 - 11:30 AM IST",
						name: "Zeeshan",
						description:
							"Build and maintain Upgradable Smart Contracts using Foundry and OpenZeppelin",
					},
					{
						time: "11:45 - 12:15 PM IST",
						name: "Salil Naik",
						description: "Simplifying the Bridging UX in web3",
					},
					{
						time: "12:30 - 01:00 PM IST",
						name: "Venkatesh Kanchanpally",
						description: "zkEVM-Types of zkEVM-Kakarot in Focus",
					},
					{
						time: "01:00 - 02:00 PM IST",
						name: "Lunch",
						description: "Lunch for everyone",
					},
					{
						time: "02:00 - 02:30 PM IST",
						name: "Saksham Taneja",
						description:
							"Uplifting Developer Communities Though Hackathons and Programs",
					},
					{
						time: "02:45 - 03:15 PM IST",
						name: "Pakam Joel Johnson",
						description:
							"Navigating the Tides of Fundraising  in web3",
					},
					{
						time: "03:30 - 04:00 PM IST",
						name: "Simarpreet Singh",
						description:
							"Upcoming trend of the Web3 Industry : Unlocking Metaverse-as-a-Service",
					},
					{
						time: "04:15 - 04:45 PM IST",
						name: "Satheesh Kumar PADDOLKER",
						description:
							"Fusion of #web3 with #AI and #ML and how it benefits humanity?",
					},
					{
						time: "05:00 - 05:30 PM IST",
						name: "Panel discussion",
						description: "Blockchain Reps",
					},
					{
						time: "07:00 PM IST",
						name: "Cruise After Party",
						description: "",
					},
				],
				3: [
					{
						time: "10:30 - 11:30 AM IST",
						name: "Yash Jagtap",
						description:
							"The Power of The Graph Protocol: Introduction to Substreams",
					},
					{
						time: "11:45 - 12:15 PM IST",
						name: "Aditya V",
						description:
							"Web3 Marketing: Opportunities and Challenges for Marketers",
					},
					{
						time: "12:30 - 01:00 PM IST",
						name: "Guru",
						description:
							"Privacy preserving web3 communication : Waku Protocol",
					},
					{
						time: "01:00 - 02:00 PM IST",
						name: "Lunch",
						description: "Lunch for everyone",
					},
					{
						time: "02:00 - 02:30 PM IST",
						name: "Shabbir Khan",
					},
					{
						time: "02:45 - 03:15 PM IST",
						name: "Yax Sheth",
						description:
							"How W2E is winning Over P2E in Crypto Gaming",
					},
					{
						time: "03:30 - 04:00 PM IST",
						name: "Divyanshu Urmaliya",
						description:
							"Proof of Learn: Revolutionizing Web3 Education with Metacrafters",
					},
					// {
					// 	time: "04:15 - 04:45 PM IST",
					// 	name: "Akansha Jain",
					// 	description: "Internals of MEV",
					// },
					{
						time: "04:15 - 04:45 PM IST",
						name: "Vivek Pal",
						description:
							"The Role of Web3 in Building a More Decentralized and Resilient Computing Infrastructure",
					},
					{
						time: "07:00 PM IST",
						name: "Cruise After Party",
						description: "",
					},
				],
			},
		];

	const [eventData, setEventData] = useState(event[0]);
	const [day, setDay] = useState(1);
	const [stage, setStage] = useState(2);

	React.useEffect(() => {
		day !== 1 && localStorage.setItem("current-stage", stage.toString());
	}, [stage]);

	return (
		<>
			<Heading className="font-primary">Agenda</Heading>
			<AgendaSlider>
				<AgendaDiv>
					<AgendaHeading>
						<Day
							className="font-primary"
							day={day}
							onClick={e => {
								setEventData(event[0]);
								setDay(1);
								setStage(2);
							}}
						>
							11 {day == 1 ? "Aug '23" : ""}
						</Day>
						<Day
							className="font-primary"
							day={day}
							onClick={e => {
								setEventData(event[1]);
								setDay(2);
								setStage(
									parseInt(
										localStorage.getItem(
											"current-stage"
										) as string
									) || 1
								);
							}}
						>
							12 {day == 2 ? "Aug '23" : ""}
						</Day>
						<Day
							className="font-primary"
							day={day}
							onClick={e => {
								setEventData(event[2]);
								setDay(3);
								setStage(
									parseInt(
										localStorage.getItem(
											"current-stage"
										) as string
									) || 1
								);
							}}
						>
							13 {day == 3 ? "Aug '23" : ""}
						</Day>
						<Day
							className="font-primary"
							day={day}
							onClick={e => {
								setEventData(event[3]);
								setDay(4);
								setStage(
									parseInt(
										localStorage.getItem(
											"current-stage"
										) as string
									) || 1
								);
							}}
						>
							14 {day == 4 ? "Aug '23" : ""}
						</Day>
					</AgendaHeading>
					{Object.keys(eventData).length > 1 && (
						<AgendaHeading stage={true}>
							<Stage
								className="font-primary"
								stage={stage}
								onClick={e => {
									setStage(1);
								}}
							>
								Mainstage
							</Stage>
							<Stage
								className="font-primary"
								stage={stage}
								onClick={e => {
									setStage(2);
								}}
							>
								Substage 1
							</Stage>
							<Stage
								className="font-primary"
								stage={stage}
								onClick={e => {
									setStage(3);
								}}
							>
								Substage 2
							</Stage>
						</AgendaHeading>
					)}
					<AgendaContent className={day === 1 ? `!pb-10` : ``}>
						{eventData[stage].map(event => {
							return (
								<div style={{ width: "100%" }}>
									<AgendaRow>
										<Time className="font-secondary">
											{event.time}
										</Time>
										<Space />
										<Event className="font-secondary">
											{event.name}
											<EventDescription>
												{event.description}
											</EventDescription>
											<EventTalk>{event.talk}</EventTalk>
										</Event>
									</AgendaRow>
								</div>
							);
						})}
					</AgendaContent>
				</AgendaDiv>
			</AgendaSlider>
		</>
	);
};

export default Agenda;
