import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import location1 from "../assets/glimpses/location-1.webp";
import location2 from "../assets/glimpses/location-2.webp";
import location3 from "../assets/glimpses/location-3.webp";

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 1rem 0 3rem 0;
	color: var(--text-100);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const Main = styled.div`
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Half = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`;

const Image = styled.img`
	width: 360px;
	height: 240px;
	object-fit: cover;
	margin: 2px;
	border-radius: 3px;
`;

const Heading2 = styled.div`
	font-size: 64px;
	width: 80%;
	text-align: center;
	color: var(--text-100);
`;

const Heading3 = styled.div`
	font-size: 64px;
	width: 100%;
	text-align: left;
	color: var(--text-100);
`;

const Venue = () => {
	const isMobile = useMediaQuery({
		query: "(max-width: 768px)",
	});
	return (
		<div>
			<Heading className="font-primary">Venue</Heading>
			<Main
				style={
					isMobile
						? { flexDirection: "column" }
						: { flexDirection: "row" }
				}
			>
				<Half style={isMobile ? { width: "100vw" } : { width: "45vw" }}>
					<Heading2>
						<span
							className="font-primary"
							style={
								isMobile
									? { display: "block", fontSize: "80px" }
									: { display: "block", fontSize: "145px" }
							}
						>
							Novotel Goa
						</span>
						<span
							style={
								isMobile
									? {
											display: "block",
											fontSize: "18px",
											marginTop: "-30px",
											fontWeight: "200",
											marginBottom: "50px",
									  }
									: {
											display: "block",
											fontSize: "36px",
											marginTop: "-50px",
											fontWeight: "200",
									  }
							}
						>
							Dona Silvia Resort, 403731 GOA
						</span>
					</Heading2>
				</Half>
				<Half style={isMobile ? { width: "100vw" } : { width: "55vw" }}>
					<Image src={location1} />
					<Image src={location2} />
					<Image src={location3} />
					<div
						style={{
							width: "360px",
							height: "240px",
							overflow: "hidden",
						}}
					>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3850.701941767738!2d73.94247107501029!3d15.174704985382572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbe4ca2581f9137%3A0x7dc80a6d1e0e88a8!2sNovotel%20Goa%20Dona%20Sylvia%20Resort!5e0!3m2!1sen!2sin!4v1687517894633!5m2!1sen!2sin"
							style={{
								border: "0",
								width: "100%",
								height: "100%",
							}}
							allowFullScreen={true}
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
					</div>
				</Half>
			</Main>
		</div>
	);
};

export default Venue;
