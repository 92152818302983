import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import girlscript from "../assets/girlscript_logo.webp";

const FooterDiv = styled.footer`
	color: var(--text-500);
	font-size: 12px;
	font-weight: 400;
	background: white;
	padding: 48px 18px;
	border-top: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	background: transparent;
	width: 100%;
	overflow: hidden;
	@media (max-width: 768px) {
		flex-direction: column;
	}

	.social-media a {
		position: relative;
		margin-right: 15px;
		text-decoration: none;
		color: inherit;
	}

	.social-media a:last-child {
		margin-right: 0;
	}

	.social-media a svg {
		width: 1.1rem;
		fill: currentColor;
	}

	/*-- Tooltip Social Media --*/
	.tooltip-social {
		background: #262626;
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		padding: 0.5rem 0.4rem;
		border-radius: 5px;
		font-size: 0.8rem;
		font-weight: 600;
		opacity: 0;
		pointer-events: none;
		transform: translate(-50%, -90%);
		transition: all 0.2s ease;
		z-index: 1;
	}

	.tooltip-social:after {
		content: " ";
		position: absolute;
		bottom: 1px;
		left: 50%;
		border: solid;
		border-width: 10px 10px 0 10px;
		border-color: transparent;
		transform: translate(-50%, 100%);
	}

	.social-media a .tooltip-social:after {
		border-top-color: #262626;
	}

	.social-media a:hover .tooltip-social {
		opacity: 1;
		transform: translate(-50%, -130%);
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 50%;
	margin: 0.8rem 0;
	@media (max-width: 768px) {
		width: 100% !important;
		padding-left: 0 !important;
		align-items: center !important;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	margin: 0.2rem 0;
`;

const Cell = styled.a.attrs(() => ({
	target: "_blank",
}))`
	width: 50%;
	color: var(--text-500);
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;

const CellHeader = styled(Cell)`
	cursor: default !important;
	&:hover {
		text-decoration: none;
	}
`;

const Logo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	color: var(--text-100);
	font-weight: 700;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const LogoImg = styled.img`
	height: 60px;
	border-radius: 50%;
	margin-right: 1rem;
`;

const Footer = () => {
	return (
		<FooterDiv>
			<Column
				style={{
					width: "60%",
					alignItems: "flex-start",
					paddingLeft: "4rem",
				}}
			>
				<Logo>
					<div className="flex items-center">
						<LogoImg src={logo} /> Web3Conf India
					</div>
					<LogoImg
						style={{
							height: "80px",
							marginTop: "0.33rem",
							marginLeft: "1rem",
							marginRight: 0,
						}}
						src={girlscript}
					/>
				</Logo>
				<Logo
					style={{
						color: "var(--text-500)",
						fontSize: "12px",
						fontWeight: "400",
						width: "80%",
						marginTop: "1rem",
						opacity: "0.66",
						display: "block",
					}}
				>
					<p className="text-center text-white md:text-left">
						Brought To You By GIRLSCRIPT FOUNDATION.
					</p>
					<p className="mt-2 text-center md:text-left">
						Supporting beginners in tech education since 2017 with
						programs like GirlScript Summer of Code, LetsPy, Tech In
						Slums, Uplift Project, etc. The foundation has impacted
						500,000+ learners and has been featured in Forbes Asia,
						Tedx, Booking.com Technology Playmaker, ElasticSearch
						Cause Awards, Women Tech Network Awards, Society of
						Women Engineers Magazine, etc, and has worked with over
						250+ global tech and social good companies like
						Microsoft, Redhat, Twitter, Atlassian, Filecoin,
						Polygon, Dapper Labs, Child Rights & You, etc.
					</p>
				</Logo>
			</Column>
			<Column>
				<Row>
					<CellHeader
						style={{
							color: "var(--text-100)",
							fontSize: "16px",
							fontWeight: "700",
						}}
					>
						Resources
					</CellHeader>
					<CellHeader
						style={{
							color: "var(--text-100)",
							fontSize: "16px",
							fontWeight: "700",
						}}
						className="text-right md:text-left"
					>
						Accessibility
					</CellHeader>
				</Row>
				<Row>
					<Cell href="https://2022.web3confindia.xyz">
						Web3Conf India 2022
					</Cell>
					<Cell
						className="text-right md:text-left"
						href="https://web3conf.notion.site/Goa-A-Guide-to-Must-Visit-Places-138bdb8496ac4196a04c7f6ded11e1d2"
					>
						Goa: A Guide To Must-Visit Places
					</Cell>
				</Row>
				<Row>
					<Cell href="https://hack-web3conf-23.devfolio.co/">
						Hack Web3Conf India'23
					</Cell>
					<Cell
						className="text-right md:text-left"
						href="https://www.buktrips.com/hotels/?utm_source=web&utm_medium=online&utm_campaign=web3conf_partnership"
					>
						Finding Accomodation
					</Cell>
				</Row>
				<Row>
					<Cell href="https://web3conf.notion.site/Call-for-Paper-de1046949ce943c28cf5b4168294b6f4">
						Call For Paper
					</Cell>
					<Cell
						className="text-right md:text-left"
						href="https://web3conf.notion.site/Local-Travel-Cabs-etc-ad0bdd2b578045b7b30f2301a51fa540"
					>
						Local Travel - Cabs etc.
					</Cell>
				</Row>
				<Row>
					<Cell href="https://web3conf.notion.site/Call-for-Volunteers-398a9281a4f244499ad2fe0e424f2922">
						Call For Volunteers
					</Cell>
					<Cell
						className="text-right md:text-left"
						href="https://web3conf.notion.site/Pubs-Clubs-Restaurants-a810e43e12fd4cbc9bd830ac434d58bc"
					>
						Pubs, Clubs, Restaurants
					</Cell>
				</Row>
				<Row>
					<Cell href="https://web3conf.notion.site/Scholarships-b6438b2842ce4f2e91ea11ba62b7608b">
						Scholarships
					</Cell>
					<Cell
						className="text-right md:text-left"
						href="https://web3conf.notion.site/Visa-Information-for-Foreign-Nationals-0e5954a5864c4563908c99547376ef0f"
					>
						Visa Info
					</Cell>
				</Row>
				<Row>
					<Cell href="https://web3conf.notion.site/Tickets-803cd19843864cba9d136a05a764f049">
						Tickets
					</Cell>
					<Cell
						className="text-right md:text-left"
						href="https://web3conf.notion.site/Any-Other-Help-e6932be57892488191504e6bacf02378"
					>
						Need Help?
					</Cell>
				</Row>
				<Row>
					<Cell href="https://web3conf.notion.site/Sponsorships-f83ce87beb674ac18749e683a652c963">
						Sponsorship
					</Cell>
					<Cell />
				</Row>
			</Column>
		</FooterDiv>
	);
};

export default Footer;
