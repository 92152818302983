import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	--background: hsl(264, 62%, 44%);
	--background-transparent: hsla(264, 69%, 15%, 0.75);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	margin: 0 0 1rem 0;
	color: var(--text-100);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const TicketSlider = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75%;
	margin: 3rem auto;
	gap: 30px;
	flex-wrap: wrap;
`;

const Ticket = styled.div`
	flex-shrink: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 300px;
	justify-self: center;
	position: relative;
	overflow: hidden;
	&:after {
		content: "";
		position: absolute;
		display: block;
		width: calc(100% - 60px);
		transform: translateX(30px);
		left: 0;
		top: 66.25%;
		border-top: 3px dashed rgb(197, 197, 197);
	}
`;

const TicketHalf = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	background: white;
	padding: 20px;
	color: var(--text-400);
	width: calc(100% - 40px);
	overflow: hidden;
	position: relative;
	&:first-child {
		border-bottom: 0;
	}
	&:nth-child(2) {
		border-top: 0;
	}
`;

const TicketNumber = styled.div`
	color: var(--text-600);
	font-weight: 500;
`;

const Text = styled.div`
	color: var(--text-400);
	font-weight: 700;
`;

const Button = styled.a`
	display: block;
	width: 100%;
	background: var(--palette-200);
	border-top: 2px solid var(--text-600);
	padding: 0.5rem 0;
	user-select: none;
	color: var(--text-400);
	font-weight: 900;
	letter-spacing: 1px;
	font-size: 14px;
	margin-top: 0.5rem;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Applications = () => {
	return (
		<Wrapper>
			<Heading className="font-primary">Applications</Heading>

			<TicketSlider>
				<Ticket>
					<TicketHalf
						style={{
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
						}}
					>
						<TicketNumber>Application #1</TicketNumber>
						<Text
							className="font-secondary"
							style={{
								fontSize: "28px",
								marginTop: "15px",
							}}
						>
							Volunteer
						</Text>
						<Text
							className="font-secondary"
							style={{ fontSize: "18px" }}
						>
							Join the team of Web3Conf volunteers!
						</Text>

						<Text
							style={{
								color: "var(--text-600)",
								marginTop: "15px",
								fontSize: "14px",
							}}
						>
							Last Date:
						</Text>
						<Text
							className="font-secondary"
							style={{ fontSize: "14px", marginBottom: "20px" }}
						>
							30th March '23
						</Text>

						<Text
							style={{
								color: "var(--text-600)",
								marginTop: "15px",
								fontSize: "14px",
							}}
						>
							Covers:
						</Text>
						<div className="flex flex-wrap items-center gap-2">
							<div className="px-2 py-1 font-sans text-xs font-bold rounded-md select-none text-violet-800 bg-violet-200">
								Free Passes
							</div>
						</div>
					</TicketHalf>

					<TicketHalf
						style={{
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							flexDirection: "row",
						}}
					>
						<div style={{ width: "50%" }}>
							<Button
								href={"#"}
								style={{
									textDecoration: "none",
									cursor: "not-allowed",
									pointerEvents: "none",
									background: "#fffbab",
									color: "#636359",
								}}
							>
								Applications Closed
							</Button>
						</div>
					</TicketHalf>
				</Ticket>

				<Ticket>
					<TicketHalf
						style={{
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
						}}
					>
						<TicketNumber>Application #2</TicketNumber>
						<Text
							className="font-secondary"
							style={{
								fontSize: "28px",
								marginTop: "15px",
							}}
						>
							Scholarship
						</Text>
						<Text
							className="font-secondary"
							style={{ fontSize: "18px" }}
						>
							Get a chance to attend Web3Conf for free!
						</Text>

						<Text
							style={{
								color: "var(--text-600)",
								marginTop: "15px",
								fontSize: "14px",
							}}
						>
							Last Date:
						</Text>
						<Text
							className="font-secondary"
							style={{ fontSize: "14px", marginBottom: "20px" }}
						>
							30th March '23
						</Text>

						<Text
							style={{
								color: "var(--text-600)",
								marginTop: "15px",
								fontSize: "14px",
							}}
						>
							Covers:
						</Text>
						<div className="flex flex-wrap items-center gap-2">
							<div className="px-2 py-1 font-sans text-xs font-bold rounded-md select-none text-violet-800 bg-violet-200">
								Free Passes
							</div>
						</div>
					</TicketHalf>

					<TicketHalf
						style={{
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							flexDirection: "row",
						}}
					>
						<div style={{ width: "50%" }}>
							<Button
								href={"#"}
								style={{
									textDecoration: "none",
									cursor: "not-allowed",
									pointerEvents: "none",
									background: "#fffbab",
									color: "#636359",
								}}
							>
								Applications Closed
							</Button>
						</div>
					</TicketHalf>
				</Ticket>

				<Ticket>
					<TicketHalf
						style={{
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
						}}
					>
						<TicketNumber>Application #3</TicketNumber>
						<Text
							className="font-secondary"
							style={{
								fontSize: "28px",
								marginTop: "15px",
							}}
						>
							Speaker
						</Text>
						<Text
							className="font-secondary"
							style={{ fontSize: "18px" }}
						>
							Submit your call for paper today!
						</Text>

						<Text
							style={{
								color: "var(--text-600)",
								marginTop: "15px",
								fontSize: "14px",
							}}
						>
							Last Date:
						</Text>
						<Text
							className="font-secondary"
							style={{ fontSize: "14px", marginBottom: "20px" }}
						>
							15th May '23
						</Text>

						<Text
							style={{
								color: "var(--text-600)",
								marginTop: "15px",
								fontSize: "14px",
							}}
						>
							Covers:
						</Text>
						<div className="flex flex-wrap items-center gap-2">
							<div className="px-2 py-1 font-sans text-xs font-bold rounded-md select-none text-violet-800 bg-violet-200">
								Free Passes
							</div>
						</div>
					</TicketHalf>

					<TicketHalf
						style={{
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							flexDirection: "row",
						}}
					>
						<div style={{ width: "50%" }}>
							<Button
								href={"#"}
								style={{
									textDecoration: "none",
									cursor: "not-allowed",
									pointerEvents: "none",
									background: "#fffbab",
									color: "#636359",
								}}
							>
								Applications Closed
							</Button>
						</div>
					</TicketHalf>
				</Ticket>
			</TicketSlider>
		</Wrapper>
	);
};

export default Applications;
