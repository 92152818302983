import React from "react";
import styled from "styled-components";
import iosStore from "../assets/ios_appstore.png";
import androidStore from "../assets/google_playstore.png";
import qrCode from "../assets/qrcode.png";
const Wrapper = styled.div`
	--background: hsl(264, 62%, 44%);
	--background-transparent: hsla(264, 69%, 15%, 0.75);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
`;

const Heading = styled.div`
	font-size: 48px;
	width: 100%;
	text-align: center;
	color: var(--text-100);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.2rem auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			to right,
			transparent,
			var(--border-300),
			transparent
		);
		margin: 0.5rem auto;
	}
`;

const TicketSlider = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75%;
	margin: 3rem auto;
	gap: 30px;
	flex-wrap: wrap;
`;

const TicketNumber = styled.div`
	color: var(--text-600);
	font-weight: 500;
	margin-top: 1rem;
`;

const Text = styled.div`
	color: var(--text-400);
	font-weight: 700;
`;

const Button = styled.a`
	display: block;
	width: 100%;
	background: var(--palette-200);
	border-top: 2px solid var(--text-600);
	padding: 0.5rem 0;
	user-select: none;
	color: var(--text-400);
	font-weight: 900;
	letter-spacing: 1px;
	font-size: 14px;
	margin-top: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Card = styled.div`
	width: 600px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	background: white;
	border: 5px solid black;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
`;

const AppDownload = () => {
	return (
		<Wrapper id="AppStore">
			<Heading className="font-primary">Event App</Heading>

			<TicketSlider>
				<Card>
					<TicketNumber>Download for iOS and Android</TicketNumber>
					<Text
						className="font-secondary"
						style={{
							fontSize: "28px",
							margin: "15px 2rem 0 2rem",
							textAlign: "center",
						}}
					>
						Web3Conf Event App
					</Text>
					<div className="flex flex-row justify-evenly">
						<Text
							className="font-secondary w-full sm:w-3/4 text-justify"
							style={{
								fontSize: "14px",
								margin: "1rem 2rem",
							}}
						>
							Make sure you download the official event app for
							Web3Conf India 2023. Easily connect with other
							attendees, view event schedules, speaker info &
							their talk timings, get news updates, event site
							maps, bookmark all your favourites, and post on
							social feed.
						</Text>
					</div>

					<div className="w-full flex flex-row flex-wrap justify-center items-center gap-4 m-5">
						<a href="https://apps.apple.com/app/web3conf-india-23/id6452728302">
							<img
								src={iosStore}
								alt="IoS App Store"
								className="w-40"
							/>
						</a>
						<a href="https://play.google.com/store/apps/details?id=com.eventify.aj">
							<img
								src={androidStore}
								alt="Android Play Store"
								className="w-40"
							/>
						</a>
					</div>
				</Card>
			</TicketSlider>
		</Wrapper>
	);
};

export default AppDownload;
